import { Component, OnInit } from '@angular/core';
import { AdminService } from '../admin.service';
import { QuoteService } from '../../quotes/quote.service';
import { OracleService } from '../../oracle/oracle.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { OracleCard } from 'src/app/oracle/oracle-card/oracle-card';
import { delay } from 'q';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin-oracle-card',
  templateUrl: './admin-oracle-card.component.html',
  styleUrls: ['./admin-oracle-card.component.css']
})
export class AdminOracleCardComponent implements OnInit {

  adminOracleCardForm: FormGroup = new FormGroup({});

  uprightKeywords: any;
  reversedKeywords: any;
  uprightTags: any;
  reversedTags: any;


  constructor(
    private router: Router,
    public adminService: AdminService,
    public quoteService: QuoteService,
    public oracleService: OracleService
  ) { }

  ngOnInit() {
    this.initForm();
  }

  initForm() {

    this.adminOracleCardForm = new FormGroup({

      'selectedUprightKeywords': new FormControl('', Validators.required),
      'selectedReversedKeywords': new FormControl('', Validators.required),
      'selectedUprightTags': new FormControl('', Validators.required),
      'selectedReversedTags': new FormControl('', Validators.required),

    });

  }

  onSearchTags(searchForTag: string, option: string) {

    if (searchForTag.length >= 3) {

      this.quoteService.getTags(searchForTag)
        .subscribe((data) => {

          switch (option) {
            case 'UPRIGHT_KEYWORDS':
              this.uprightKeywords = data;
              break;
            case 'REVERSED_KEYWORDS':
              this.reversedKeywords = data;
              break;
            case 'UPRIGHT_TAGS':
              this.uprightTags = data;
              break;
            case 'REVERSED_TAGS':
              this.reversedTags = data;
              break;
            default:
              break;
          }
        });
    }
  }

  async onAddTag(selectedTag: any, option: string) {

    if (selectedTag.id === undefined) {

      const tagName = (selectedTag.name !== null && selectedTag.name !== undefined) ? selectedTag.name : selectedTag;
      // console.log('create new tag');
      // console.log(tagName);
      this.quoteService.createTag(tagName.toLowerCase());

      // TODO: maybe this part is not necessary
      await delay(100);
      this.quoteService.getTagByName(tagName)
        .subscribe((data) => {
          // console.log(data);

          switch (option) {
            case 'UPRIGHT_KEYWORDS':
              this.adminService.editOracleCard.upright_keywords.push(data);
              break;
            case 'REVERSED_KEYWORDS':
              this.adminService.editOracleCard.reversed_keywords.push(data);
              break;
            case 'UPRIGHT_TAGS':
              this.adminService.editOracleCard.upright_tags.push(data);
              break;
            case 'REVERSED_TAGS':
              this.adminService.editOracleCard.reversed_tags.push(data);
              break;
            default:
              break;
          }

        });
    }

    console.log('onAddTagEvent');
    console.log(this.adminService.editOracleCard.upright_keywords);
  }

  onChange(event: any) {
    console.log('onChangeTagEvent');
    console.log(this.adminService.editOracleCard.upright_keywords);
  }

  onSubmit() {

    // Fill oracle card with data

    const oracleCard = new OracleCard();
    oracleCard.id = this.adminService.editOracleCard.id;

    const selectedUprightKeywords = this.adminOracleCardForm.controls['selectedUprightKeywords'].value;
    const selectedReversedKeywords = this.adminOracleCardForm.controls['selectedReversedKeywords'].value;
    const selectedUprightTags = this.adminOracleCardForm.controls['selectedUprightTags'].value;
    const selectedReversedTags = this.adminOracleCardForm.controls['selectedReversedTags'].value;

    selectedUprightKeywords.forEach(function (tag) {
      if (tag.id !== undefined) {
        oracleCard.upright_keywords.push(tag.id);
      }
    });

    selectedReversedKeywords.forEach(function (tag) {
      if (tag.id !== undefined) {
        oracleCard.reversed_keywords.push(tag.id);
      }
    });

    selectedUprightTags.forEach(function (tag) {
      if (tag.id !== undefined) {
        oracleCard.upright_tags.push(tag.id);
      }
    });

    selectedReversedTags.forEach(function (tag) {
      if (tag.id !== undefined) {
        oracleCard.reversed_tags.push(tag.id);
      }
    });

    console.log('save oracle card');
    console.log(oracleCard);

    this.oracleService.updateOracleCard(oracleCard).subscribe(
      response => {
        console.log(response);
      },
      err => console.log(err)
    );

    this.router.navigate(['/admin']);

  }

  cancel() {
    this.router.navigate(['/admin']);
  }

}
