import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-oracle-questions',
  templateUrl: './oracle-questions.component.html',
  styleUrls: ['./oracle-questions.component.css']
})
export class OracleQuestionsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
