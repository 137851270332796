import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    API_URL = environment.API_URL;
    private httpOptions: any;
    public user: any;
    private token: string;

    constructor(private http: HttpClient) {
        this.httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' })
        };
    }

    public getUserById(user_id: string) {
        return this.http.get(this.API_URL + '/user/' + user_id, this.httpOptions);
    }

    public updateDisplayName(userId: string, displayName: string) {
        return this.http.patch<any>(`${this.API_URL}/update-display-name/` + userId + '/' + displayName, this.httpOptions);
    }

}
