import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { QuoteService } from '../quote.service';

@Component({
  selector: 'app-author',
  templateUrl: './author.component.html',
  styleUrls: ['./author.component.css']
})
export class AuthorComponent implements OnInit, OnDestroy {

  id: number;
  private sub: any;
  author: any;
  quotes: any;

  constructor(
    private route: ActivatedRoute,
    private quoteService: QuoteService
  ) { }

  ngOnInit() {

    this.sub = this.route.params.subscribe(params => {
      this.id = +params['id']; // (+) converts string 'id' to a number

      this.getAuthorInfo(this.id);
      this.getAuthorQuotes(this.id);

    });

  }

  getAuthorInfo(id: number) {

    this.quoteService.getAuthorInfo(id)
      .subscribe((data) => {
        this.author = data;
      });

  }

  getAuthorQuotes(id: number) {

    this.quoteService.getAuthorQuotes(id)
      .subscribe((data) => {
        this.quotes = data;
      });

  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

}
