import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../auth.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class SigninComponent implements OnInit {

  // An object representing the user for the login form
  public user: any;

  constructor(
    private authService: AuthenticationService
  ) { }

  ngOnInit() {

    this.user = {
      username: '',
      password: ''
    };

  }

  // signin() {
  //   this.authService.login({ 'username': this.user.username, 'password': this.user.password });
  // }

  refreshToken() {
    this.authService.refreshToken();
  }

  signout() {
    this.authService.logout();
  }

}
