import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ProfileService } from '../profile.service';
import { QuoteService } from '../../quotes/quote.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../user.service';
import { AuthenticationService } from '../../auth/auth.service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { NavigationService } from 'src/app/layout/sidenav/sidenav/navigation.service';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class ProfileComponent implements OnInit {

    quotes: any;
    user: any;
    oracleReadings: any;
    reading: any;

    constructor(
        private profileService: ProfileService,
        private quoteService: QuoteService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        public userService: UserService,
        private authService: AuthenticationService,
        private _snackBar: MatSnackBar,
        private navigationService: NavigationService,
    ) { }

    ngOnInit() {
        if (this.userService.user == null) {
            this.getUserInfo();
        }
    }

    getUserInfo() {

        const userId = window.localStorage.getItem('user_id');
        this.userService.getUserById(userId).subscribe(
            user => {
                this.userService.user = user;
                if (this.userService.user.profile_image == null) {
                    window.localStorage.setItem('profile_image', '../../../../assets/img/wolf-spirit.png.jpg');
                } else {
                    window.localStorage.setItem('profile_image', this.userService.user.profile_image);
                }
                window.localStorage.setItem('username', this.userService.user.username);
            },
            err => {
                this.authService.logout();
            }
        );
    }

}
