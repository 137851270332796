import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';

export class AuthInterceptorService implements HttpInterceptor {

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        // console.log('Request is on its way');
        const authType = window.localStorage.getItem('auth_type');

        if (authType === 'SOCIAL_AUTH') {
            const modifiedRequest = req.clone({
                headers: req.headers.set(
                    'Authorization', 'Bearer ' + window.localStorage.getItem('access_token')
                )
            });
            // console.log(modifiedRequest);
            return next.handle(modifiedRequest);
        } else {
            return next.handle(req);
        }

    }

}
