import { Component, OnInit } from '@angular/core';
import { QuoteService } from '../quote.service';
import { SvesnostService } from '../../layout/svesnost.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { delay } from 'q';
import { Quote } from 'src/app/quotes/quote';
import { Router } from '@angular/router';

@Component({
  selector: 'app-quote-edit',
  templateUrl: './quote-edit.component.html',
  styleUrls: ['./quote-edit.component.css']
})
export class QuoteEditComponent implements OnInit {

  quoteEditForm: FormGroup = new FormGroup({});

  authors: any;
  selectedAuthor: string;
  authorId: string;
  showAuthors: boolean;
  typedAuthor: string;

  showBooks: boolean;
  books: any;
  selectedBook: string;
  bookId: string;
  typedBook: string;

  tags: any;
  showTags: boolean;
  selectedTags: any;
  typedTag: string;

  constructor(
    public quoteService: QuoteService,
    public svesnostService: SvesnostService,
    private router: Router
  ) {

    this.selectedAuthor = '';
    this.authorId = '';
    this.showAuthors = false;
    this.typedAuthor = '';

    this.showBooks = false;
    this.selectedBook = '';
    this.bookId = '';

    this.showTags = false;
    this.selectedTags = [];
    this.typedTag = '';

  }

  ngOnInit() {

    this.initForm();

  }

  quoteEditClose() {

    this.router.navigate(['/wisdom/quotes']);

  }

  initForm() {

    this.quoteEditForm = new FormGroup({

      'quote': new FormControl('', [Validators.required, Validators.minLength(4)]),

      'author': new FormControl('', [Validators.required]),

      'book': new FormControl({ value: '', disabled: true }),

      'tags': new FormControl('')

    });

  }

  notValidQuote() {

    return !this.quoteEditForm.get('quote').valid && this.quoteEditForm.get('quote').touched;

  }

  notValidAuthor() {

    return !this.quoteEditForm.get('author').valid && this.quoteEditForm.get('author').touched;

  }

  getAuthorsByFullName(event: any) {

    this.typedAuthor = event.target.value;

    if (this.typedAuthor.length >= 3) {
      this.quoteService.getAuthorsByFullName(this.typedAuthor)
        .subscribe((data) => {
          this.authors = data;
        });
      this.showAuthors = true;
    } else {
      this.authors = null;
      this.showAuthors = false;
    }

  }

  getAuthorsByFirstAndLastName(event: any) {

    this.typedAuthor = event.target.value;

    if (event.target.value.length >= 3) {

      const fullName = event.target.value.split(' ');

      const firstName = fullName[0];
      let lastName = null;

      if (fullName[1] !== null && fullName[1] !== '') {
        lastName = fullName[1];
      }

      this.quoteService.getAuthorsByFirstAndLastName(firstName, lastName)
        .subscribe((data) => {
          this.authors = data;
        });
      this.showAuthors = true;

    } else {

      this.authors = null;
      this.showAuthors = false;

    }

  }

  createAuthor() {

    this.quoteService.newAuthorName = this.quoteEditForm.controls['author'].value;
    this.quoteService.newAuthor = true;

  }

  onKeyAuthor() {

    this.quoteEditForm.get('book').disable();
    this.quoteEditForm.controls['book'].setValue('');

  }

  async blurAuthors() {

    await delay(100);
    this.showAuthors = false;

  }

  selectAuthor(author: any) {


    this.quoteService.newCreatedAuthor = undefined;


    this.selectedAuthor = author.full_name;


    // TODO: refactoring
    this.authorId = author.id;
    this.quoteService.authorId = author.id;


    this.quoteEditForm.controls['author'].setValue(this.selectedAuthor);
    this.showAuthors = false;
    this.selectedBook = '';
    this.books = null;
    this.quoteEditForm.get('book').enable();
    this.quoteEditForm.controls['book'].setValue(this.selectedBook);

  }

  getAuthorBooks() {

    this.quoteService.getAuthorBooks(this.authorId)
      .subscribe((data) => {
        this.books = data;
      });
    this.showBooks = true;

  }





  authorBooksByTitle(event: any) {

    this.typedBook = event.target.value;

    if (event.target.value.length >= 3) {

      this.quoteService.authorBooksByTitle(this.authorId, event.target.value)
        .subscribe((data) => {
          this.books = data;
        });

      this.showBooks = true;

    } else {

      this.books = null;
      this.showBooks = false;

    }

  }

  createBook() {

    this.quoteService.newBookTitle = this.quoteEditForm.controls['book'].value;
    this.quoteService.newBook = true;

  }





  selectBook(book: any) {

    this.selectedBook = book.title;
    this.quoteEditForm.controls['book'].setValue(this.selectedBook);
    this.showBooks = false;
    this.bookId = book.id;

  }

  async blurBooks() {

    await delay(100);
    this.showBooks = false;

  }

  getTags(event: any) {

    this.typedTag = event.target.value;

    if (event.target.value.length >= 3) {

      this.quoteService.getTags(event.target.value)
        .subscribe((data) => {
          this.tags = data;
        });

      this.showTags = true;

    } else {

      this.tags = null;
      this.showTags = false;

    }

  }

  async createTag() {

    this.quoteService.createTag(this.typedTag);
    await delay(100);
    this.quoteService.getTagByName(this.typedTag)
      .subscribe((data) => {
        console.log(data);
        this.selectedTags.push(data);

        // create one unit of svesnost for user
        console.log('unit of svesnost quote edit');
        const userId = window.localStorage.getItem('user_id');
        this.svesnostService.generateSvesnostUnit(userId, '1');


      });

  }

  selectTag(tag: any) {

    this.showTags = false;

    const findTag = this.selectedTags.find(function (item) {
      return item.id === tag.id;
    });

    if (!findTag) {
      this.selectedTags.push(tag);
    }

    this.quoteEditForm.controls['tags'].setValue('');

  }

  removeTag(id: any) {

    const findTag = this.selectedTags.find(function (item) {
      return item.id === id;
    });

    if (findTag) {

      const removeIndex = this.selectedTags.map(function (item) { return item.id; }).indexOf(findTag.id);

      if (removeIndex !== -1) {
        this.selectedTags.splice(removeIndex, 1);
      }
    }

  }

  async blurTags() {

    await delay(100);
    this.showTags = false;

  }

  onSubmit() {

    const quote = new Quote();

    // Fill quote with data
    quote.quote_text = this.quoteEditForm.controls['quote'].value;



    if (this.quoteService.newCreatedAuthor !== undefined) {
      quote.author = this.quoteService.newCreatedAuthor.id;
    } else {
      quote.author = this.authorId;
    }



    quote.book = this.bookId;

    this.selectedTags.forEach(function (tag) {

      if (tag.id !== undefined) {
        quote.tag.push(tag.id);
      }

    });

    this.quoteService.saveQuote(quote);
    this.router.navigate(['/wisdom/quotes']);

  }

}
