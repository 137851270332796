import { Component, OnInit } from '@angular/core';
import { ProfileService } from '../profile.service';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-goal-steps',
  templateUrl: './goal-steps.component.html',
  styleUrls: ['./goal-steps.component.css']
})
export class GoalStepsComponent implements OnInit {

  goalSteps: any;

  step: any;

  constructor(
    public profileService: ProfileService,
    private modalService: NgbModal
  ) { }

  ngOnInit() {

    this.getGoalSteps();

  }

  getGoalSteps() {

    this.profileService.getGoalSteps()
      .subscribe((data) => {

        this.goalSteps = data;

        this.profileService.currentSteps = this.goalSteps.filter(
          step => step.stage.code === 'CURRENT_STEP'
        );

        this.profileService.futureSteps = this.goalSteps.filter(
          step => step.stage.code === 'FUTURE_STEP'
        );

        this.profileService.completedSteps = this.goalSteps.filter(
          step => step.stage.code === 'COMPLETED_STEP'
        );

      });
  }

  // TODO: stepStageCodeId is hardcoded
  drop(event: CdkDragDrop<string[]>, stepStageCode: string) {

    let goalStepId: string;
    let stepStageCodeId: number;
    let newPosition: number;

    // console.log(event.previousContainer.id);

    switch (stepStageCode) {

      case ('CURRENT_STEP'): {

        goalStepId = this.getGoalStepId(event.previousContainer.id, event.previousIndex.toString());
        stepStageCodeId = 1;
        newPosition = event.currentIndex;
        break;

      }
      case ('FUTURE_STEP'): {

        goalStepId = this.getGoalStepId(event.previousContainer.id, event.previousIndex.toString());
        stepStageCodeId = 2;

        if (event.previousContainer === event.container) {
          newPosition = event.currentIndex + this.profileService.currentSteps.length;
        } else {
          newPosition = event.currentIndex + this.profileService.currentSteps.length - 1;
        }
        break;

      }
      case 'COMPLETED_STEP': {

        goalStepId = this.getGoalStepId(event.previousContainer.id, event.previousIndex.toString());
        stepStageCodeId = 3;

        if (event.previousContainer === event.container) {
          newPosition = event.currentIndex + this.profileService.currentSteps.length + this.profileService.futureSteps.length;
        } else {
          newPosition = event.currentIndex + this.profileService.currentSteps.length + this.profileService.futureSteps.length - 1;
        }
        break;

      }
      default: {
        break;
      }

    }

    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }

    if (true) {

      console.log();
      console.log('step id: ' + goalStepId);
      console.log('new position: ' + newPosition);
      console.log('stage code id: ' + stepStageCodeId);

      this.profileService.updateGoalStepPosition(goalStepId, newPosition.toString(), stepStageCodeId.toString())
        .subscribe((data) => {

          this.profileService.getCurrentGoalStep();

        });

    }

  }

  getGoalStepId(containerId: string, index: string) {

    let goalStepId: string;

    switch (containerId) {

      case ('current-steps'): {

        goalStepId = this.profileService.currentSteps[index].id;
        break;

      }
      case ('future-steps'): {

        goalStepId = this.profileService.futureSteps[index].id;
        break;

      }
      case 'completed-steps': {

        goalStepId = this.profileService.completedSteps[index].id;
        break;

      }
      default: {
        break;
      }

    }

    return goalStepId;

  }

  open(content, step: any) {

    let newStep = false;
    if (step == null) {
      newStep = true;
      step = { description: '' };
    }

    this.step = step;

    this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title', centered: true,
      windowClass: 'modal-holder'
    }).result.then((result) => {

      if (result === 'SAVE_STEP') {

        if (newStep) {

          this.profileService.createNewStepTowardGoal(step.description)
            .subscribe(
              response => {
                this.getGoalSteps();
                this.profileService.getCurrentGoalStep();
                console.log(response);
              },
              err => console.log(err)
            );

        } else {

          this.profileService.updateGoalStepDescription(step.id.toString(), step.description)
            .subscribe((data) => {
              this.profileService.getCurrentGoalStep();
            });

        }

      } else if (!newStep && result === 'MOVE_TO_FUTURE_STEP' || result === 'MOVE_TO_COMPLETED_STEP') {

        let stepStageCodeId: string;
        result === 'MOVE_TO_FUTURE_STEP' ? stepStageCodeId = '2' : stepStageCodeId = '3';

        let newPosition: number;
        if (result === 'MOVE_TO_FUTURE_STEP') {
          newPosition = this.profileService.currentSteps.length - 1;
        } else {
          newPosition = this.profileService.currentSteps.length + this.profileService.futureSteps.length - 1;
        }

        this.profileService.updateGoalStepPosition(step.id, newPosition.toString(), stepStageCodeId)
          .subscribe((data) => {

            this.getGoalSteps();
            this.profileService.getCurrentGoalStep();

          });

      }

    }, (reason) => {

      console.log(reason);

    });

  }

}
