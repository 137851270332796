import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../../layout/sidenav/sidenav/navigation.service';
import { OracleService } from '../oracle.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-oracle-button',
  templateUrl: './oracle-button.component.html',
  styleUrls: ['./oracle-button.component.css']
})
export class OracleButtonComponent implements OnInit {

  constructor(
    private navigationService: NavigationService,
    private oracleService: OracleService,
    private router: Router
  ) { }

  ngOnInit() {
  }

  askQuestion() {

    if (this.navigationService.navigationPages['spirit_animals_is_active']) {
      this.oracleService.selectedDeckCode = 'SPIRIT_ANIMALS';
      this.router.navigate(['/wisdom/spiritanimals/oracle']);
    } else if (this.navigationService.navigationPages['tarot_is_active']) {
      this.oracleService.selectedDeckCode = 'RIDER_WAITE';
      this.router.navigate(['/wisdom/tarot/oracle']);
    } else if (this.navigationService.navigationPages['runes_is_active']) {
      this.oracleService.selectedDeckCode = 'ELDER_FUTHARK';
      this.router.navigate(['/wisdom/runes/oracle']);
    }

  }

}
