import { Component, OnInit } from '@angular/core';
import { WisdomService } from '../wisdom.service';
import { OracleService } from 'src/app/oracle/oracle.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-oracle-card-wisdom',
  templateUrl: './oracle-card-wisdom.component.html',
  styleUrls: ['./oracle-card-wisdom.component.css']
})
export class OracleCardWisdomComponent implements OnInit {

  constructor(
    public wisdomService: WisdomService,
    public oracleService: OracleService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {

    if (this.wisdomService.selectedOracleCard == null) {
      this.route.params.subscribe(params => {
        const id = params['id'];
        this.oracleService.getOracleCardById(id)
          .subscribe((data) => {
            this.wisdomService.selectedOracleCard = data;
          });
      });
    }

  }

}
