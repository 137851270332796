import { Component, OnInit } from '@angular/core';
import { OracleService } from '../../oracle.service';
import { Tarot } from 'src/app/oracle/tarot';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';

@Component({
  selector: 'app-three-card-reading',
  templateUrl: './three-card-reading.component.html',
  styleUrls: ['./three-card-reading.component.css']
})
export class ThreeCardReadingComponent implements OnInit {

  cardNumbers: number[];
  pickedCards: Tarot[];
  selectedCard: any;

  selectCardPast: boolean;
  selectCardPresent: boolean;
  selectCardFuture: boolean;

  scrollToConfig: ScrollToConfigOptions;

  constructor(
    private oracleService: OracleService,
    private scrollToService: ScrollToService
  ) {

    this.selectCardPast = false;
    this.selectCardPresent = false;
    this.selectCardFuture = false;

    this.scrollToConfig = {
      target: 'scrollToDestination'
    };

  }

  ngOnInit() {

    this.threeCardSpread();

  }

  threeCardSpread() {

    const oracleId = this.oracleService.reading.oracle.id;
    const reading = this.oracleService.reading.reading;
    this.cardNumbers = Array.from(reading.split(',').map(Number));

    this.oracleService.getOracleCards(oracleId, this.cardNumbers)

      .subscribe((data) => {

        this.pickedCards = this.oracleService.orderOracleCards(data, this.cardNumbers);
        // this.spreadInfo = this.oracleService.calculateSpreadInfo(this.pickedCards);

      });

  }

  selectCard(card, selectCard) {

    this.selectedCard = card;

    switch (selectCard) {
      case 'selectCardPast': {
        this.selectCardPast = true;
        this.selectCardPresent = false;
        this.selectCardFuture = false;
        break;
      }
      case 'selectCardPresent': {
        this.selectCardPast = false;
        this.selectCardPresent = true;
        this.selectCardFuture = false;
        break;
      }
      case 'selectCardFuture': {
        this.selectCardPast = false;
        this.selectCardPresent = false;
        this.selectCardFuture = true;
        break;
      }
      default: {
        this.selectCardPast = false;
        this.selectCardPresent = false;
        this.selectCardFuture = false;
        break;
      }
    }

    this.scrollToService.scrollTo(this.scrollToConfig);

  }

}
