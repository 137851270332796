import { Component, OnInit, Input } from '@angular/core';
import { WisdomService } from 'src/app/oracle/wisdom.service';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-oracle-card',
  templateUrl: './oracle-card.component.html',
  styleUrls: ['./oracle-card.component.css']
})
export class OracleCardComponent implements OnInit {

  @Input() oracleCard: any;
  @Input() oracleCode: any;
  @Input() cardsNumber: number;

  constructor(
    public wisdomService: WisdomService,
    private router: Router,
    private ngxService: NgxUiLoaderService
  ) { }

  ngOnInit() {
  }

  selectOracleCard(oracleCard: any, oracleCode: string) {
    this.wisdomService.selectedOracleCard = oracleCard;

    switch (oracleCode) {
      case 'SPIRIT_ANIMALS':
        this.router.navigate(['/wisdom/spiritanimals/', oracleCard.id, oracleCard.name]);
        break;
      case 'RIDER_WAITE':
        this.router.navigate(['/wisdom/tarot/', oracleCard.id, oracleCard.name]);
        break;
      case 'ELDER_FUTHARK':
        this.router.navigate(['/wisdom/runes/', oracleCard.id, oracleCard.name]);
        break;
      default:
        break;
    }
  }

  imageLoad() {
    this.wisdomService.numOfImagesLoaded++;
    if (this.wisdomService.numOfImagesLoaded === this.cardsNumber) {
      this.ngxService.stopBackground('do-background-things');
    }
  }

}
