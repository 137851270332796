import { Component, OnInit } from '@angular/core';
import { LibraryService } from '../../quotes/library.service';

@Component({
  selector: 'app-profile-library',
  templateUrl: './profile-library.component.html',
  styleUrls: ['./profile-library.component.css']
})
export class ProfileLibraryComponent implements OnInit {

  library: any;

  constructor(
    private libraryService: LibraryService
  ) { }

  ngOnInit() {

    this.getUserLibrary();
  }

  getUserLibrary() {

    this.libraryService.getUserLibrary('1')
      .subscribe((data) => {
        this.library = data;
      });
  }

}
