import { Component, OnInit, Input } from '@angular/core';
// import { Quote } from '../../models/quote';
import { TagService } from '../tag.service';
import { Quote } from 'src/app/quotes/quote';

@Component({
  selector: 'app-quote',
  templateUrl: './quote.component.html',
  styleUrls: ['./quote.component.css']
})
export class QuoteComponent implements OnInit {
  @Input() quote: any;

  firstChar: CharacterData;
  restOfQuote: string;

  constructor(
    private tagService: TagService
  ) { }

  ngOnInit() {

    this.firstChar = this.quote.quote_text.substring(0, 1);
    this.restOfQuote = this.quote.quote_text.substring(1, this.quote.quote_text.length);

  }

  addTag(id: number, name: string): void {

    this.tagService.addTag(id, name);

  }

  isTagSelected(name): boolean {

    for (const selectedTag of this.tagService.selectedTags) {
      if (name === selectedTag.name) {
        return true;
      }
    }
    return false;

  }

}
