import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { OracleService } from '../oracle/oracle.service';
import { environment } from 'src/environments/environment';
import { UserService } from '../profile/user.service';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {

    API_URL = environment.API_URL;
    private httpOptions: any;
    public token: string;
    public errors: any = [];
    private logedIn: boolean;

    constructor(
        private http: HttpClient,
        private router: Router,
        private jwtHelper: JwtHelperService,
        private oracleService: OracleService,
        private userSertvice: UserService
    ) {
        this.httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' })
        };
        this.logedIn = false;
    }

    // #region django-rest-framework-social-oauth2

    public convertToken(grantType: string, clientId: string, clientSecret: string, backend: string, token: string) {

        this.http.post(
            this.API_URL + '/auth/convert-token?' + 'grant_type=' + grantType + '&client_id=' + clientId + '&client_secret=' +
            clientSecret + '&backend=' + backend + '&token=' + token,
            this.httpOptions
        ).subscribe(
            response => {
                // console.log(response);
                this.http.get(`${this.API_URL}/get-user-by-access-token/` + response['access_token']).subscribe(
                    data => {
                        this.updateSocialAuthData(data);
                        this.logedIn = true;

                        const newReading = localStorage.getItem('save_new_reading');
                        if (newReading) {

                            const reading = JSON.parse(newReading);
                            const randomNumbers = reading.randomNumbers;
                            const oracleId = reading.oracle.id;
                            const spreadId = reading.spread.id;
                            const question = reading.question;
                            const keywords = null;
                            this.oracleService.saveOracleReading(randomNumbers, oracleId, spreadId, question, keywords);
                            localStorage.removeItem('save_new_reading');
                            this.router.navigate(['/profile']);

                        } else {
                            this.router.navigate(['/profile']);
                        }
                    },
                    err => {
                        console.log(err);
                    }
                );

            },
            err => console.log(err)
        );

    }
    // #endregion

    // Uses http.post() to get an auth token from djangorestframework-jwt endpoint
    public login(user) {
        this.http.post(this.API_URL + '/api-token-auth/', JSON.stringify(user), this.httpOptions).subscribe(
            data => {
                // console.log(data['token']);
                this.updateLocalAuthData(data['token']);
                this.logedIn = true;
                this.router.navigate(['/profile']);
            },
            err => {
                this.errors = err['error'];
            }
        );
    }

    // Refreshes the JWT token, to extend the time the user is logged in
    public refreshToken() {
        this.http.post(this.API_URL + '/api-token-refresh/', JSON.stringify({ token: this.token }), this.httpOptions).subscribe(
            data => {
                this.updateLocalAuthData(data['token']);
            },
            err => {
                this.errors = err['error'];
            }
        );
    }

    public logout() {
        window.localStorage.removeItem('access_token');
        window.localStorage.removeItem('user_id');
        window.localStorage.removeItem('auth_type');
        this.logedIn = false;
        this.userSertvice.user = null;
        this.router.navigate(['/wisdom/quotes']);
    }

    private updateLocalAuthData(token) {
        this.token = token;
        const tokenDecoded = this.jwtHelper.decodeToken(this.token);
        window.localStorage.setItem('user_id', tokenDecoded.user_id);
        window.localStorage.setItem('access_token', token);
        window.localStorage.setItem('auth_type', 'LOCAL_AUTH');
    }

    private updateSocialAuthData(token) {
        window.localStorage.setItem('user_id', token.user.id);
        window.localStorage.setItem('token_expires', token.expires);
        window.localStorage.setItem('access_token', token.token);
        window.localStorage.setItem('auth_type', 'SOCIAL_AUTH');
    }

    public isLoggedIn() {

        const authType = window.localStorage.getItem('auth_type');
        if (authType === 'LOCAL_AUTH') {
            if (this.jwtHelper.isTokenExpired() && this.logedIn) {
                this.logout();
            }
            return !this.jwtHelper.isTokenExpired();
        } else if (authType === 'SOCIAL_AUTH') {
            return true;
        } else {
            return false;
        }

    }

    public isAdmin(): boolean {
        return window.localStorage.getItem('user_id') === '1';
    }

}
