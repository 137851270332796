import { Component, OnInit, Inject, Optional } from '@angular/core';
import { AuthService, SocialUser } from 'angularx-social-login';
import { FacebookLoginProvider, GoogleLoginProvider } from 'angularx-social-login';
import { AuthenticationService } from '../auth.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-social-auth',
  templateUrl: './social-auth.component.html',
  styleUrls: ['./social-auth.component.css']
})
export class SocialAuthComponent implements OnInit {

  private user: SocialUser;
  private loggedIn: boolean;

  grantType: string;
  clientId: string;
  clientSecret: string;
  backend: string;
  token: string;

  constructor(
    private socialAuthService: AuthService,
    private authService: AuthenticationService,
    @Optional() public dialogRef: MatDialogRef<SocialAuthComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.socialAuthService.authState.subscribe((user) => {
      this.user = user;
      this.loggedIn = (user != null);
    });
  }

  convertToken(user: any): void {

    this.grantType = 'convert_token';
    this.clientId = 'G4433hejqINPywRm1DhUpDGXjyrrNWzQwW9eE09t';
    this.clientSecret = '4HmCrIVSDup3BVmqQvaaKaa8B4VMWpVvxR9v9WU68vEgfDN1Pp4g2vRHhS5wAIn2n' +
      'tOE572LjWmK8HFvi8gj6S5FPFYVZHqobSDv8LH5OKayGABOw9EDLQKawZOTbbmu';
    this.backend = this.user.provider.toLowerCase();
    if (this.backend === 'google') {
      this.backend += '-oauth2';
    }
    this.token = this.user.authToken;
    this.authService.convertToken(this.grantType, this.clientId, this.clientSecret, this.backend, this.token);

  }

  signInWithGoogle(): void {
    this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then(result => {
      this.convertToken(result);
      if (this.dialogRef) {
        this.dialogRef.close();
      }
    });
  }

  signInWithFB(): void {
    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).then(result => {
      this.convertToken(result);
      if (this.dialogRef) {
        this.dialogRef.close();
      }
    });
  }

  signOut(): void {
    this.socialAuthService.signOut();
  }

}
