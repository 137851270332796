import { Injectable } from '@angular/core';
import config from '../../assets/data/config.json';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  constructor() { }

  public getOracleCardsNumber(oracleCode: string) {
    return config.oracleCardsNumber[oracleCode];
  }

}
