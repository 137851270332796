import { Component, OnInit } from '@angular/core';
import { OracleService } from '../../oracle.service';
import { Tarot } from 'src/app/oracle/tarot';

@Component({
  selector: 'app-one-card-reading',
  templateUrl: './one-card-reading.component.html',
  styleUrls: ['./one-card-reading.component.css']
})
export class OneCardReadingComponent implements OnInit {

  cardNumbers: number[];
  pickedCards: Tarot[];

  constructor(
    private oracleService: OracleService
  ) {
    this.pickedCards = [];
  }

  ngOnInit() {

    this.oneCardSpread();

  }

  oneCardSpread() {

    const oracleId = this.oracleService.reading.oracle.id;
    const reading = this.oracleService.reading.reading;
    this.cardNumbers = Array.from(reading.split(',').map(Number));

    this.oracleService.getOracleCards(oracleId, this.cardNumbers)

      .subscribe((data) => {

        this.pickedCards = this.oracleService.orderOracleCards(data, this.cardNumbers);
        // this.spreadInfo = this.oracleService.calculateSpreadInfo(this.pickedCards);

      });

  }

}
