import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCardModule } from '@angular/material/card';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatChipsModule } from '@angular/material/chips';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { JwtModule } from '@auth0/angular-jwt';
import { NgProgressModule } from '@ngx-progressbar/core';
import { NgProgressHttpModule } from '@ngx-progressbar/http';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgDynamicBreadcrumbModule } from 'ng-dynamic-breadcrumb';

import { SocialLoginModule } from 'angularx-social-login';

import { AppComponent } from './app.component';

import { NgxUiLoaderModule } from 'ngx-ui-loader';

// explore components
import { ExploreComponent } from './explore/explore/explore.component';
import { WorldComponent } from './explore/world/world.component';
import { MindComponent } from './explore/mind/mind.component';
import { WorldNavComponent } from './explore/world-nav/world-nav.component';
import { PostComponent } from './explore/post/post.component';
import { PostEditComponent } from './explore/post-edit/post-edit.component';
import { PostListComponent } from './explore/post-list/post-list.component';
import { CompassionComponent } from './explore/compassion/compassion.component';

// master components
import { NavMenuComponent } from './layout/nav-menu/nav-menu.component';
import { SigninComponent } from './auth/signin/signin.component';
import { BreadcrumbComponent } from './layout/breadcrumb/breadcrumb.component';
import { SocialAuthComponent } from './auth/social-auth/social-auth.component';

// quotes components
import { QuotesListComponent } from './quotes/quotes-list/quotes-list.component';
import { QuoteComponent } from './quotes/quote/quote.component';
import { QuotesComponent } from './quotes/quotes/quotes.component';
import { AuthorComponent } from './quotes/author/author.component';
import { TagsComponent } from './quotes/tags/tags.component';
import { QuotesActionsComponent } from './quotes/quotes-actions/quotes-actions.component';
import { QuoteEditComponent } from './quotes/quote-edit/quote-edit.component';
import { AuthorEditComponent } from './quotes/author-edit/author-edit.component';
import { BookEditComponent } from './quotes/book-edit/book-edit.component';
import { QuotesFromKeywordsComponent } from './quotes/quotes-from-keywords/quotes-from-keywords.component';
import { QuoteFromReadingComponent } from './quotes/quote-from-reading/quote-from-reading.component';
import { OracleCardComponent } from './oracle/oracle-card/oracle-card.component';

// oracle components
import { TarotComponent } from './oracle/tarot/tarot.component';
import { RunesComponent } from './oracle/runes/runes.component';
import { SpreadsComponent } from './oracle/spreads/spreads.component';
import { OneCardComponent } from './oracle/spreads/one-card/one-card.component';
import { ThreeCardComponent } from './oracle/spreads/three-card/three-card.component';
import { StarSpreadComponent } from './oracle/spreads/star-spread/star-spread.component';
import { CelticCrossComponent } from './oracle/spreads/celtic-cross/celtic-cross.component';
import { OracleQuestionsComponent } from './oracle/oracle-questions/oracle-questions.component';
import { SpreadInfoComponent } from './oracle/spreads/spread-info/spread-info.component';
import { OracleActionsComponent } from './oracle/oracle-actions/oracle-actions.component';
import { SpiritAnimalComponent } from './oracle/spirit-animal/spirit-animal.component';
import { OracleReadingsComponent } from './oracle/oracle-readings/oracle-readings.component';
import { ReadingInfoComponent } from './oracle/reading-info/reading-info.component';
import { ReadingComponent } from './oracle/reading/reading.component';
import { OneCardReadingComponent } from './oracle/reading/one-card-reading/one-card-reading.component';
import { ThreeCardReadingComponent } from './oracle/reading/three-card-reading/three-card-reading.component';
import { StarReadingComponent } from './oracle/reading/star-reading/star-reading.component';
import { CelticCrossReadingComponent } from './oracle/reading/celtic-cross-reading/celtic-cross-reading.component';
import { QuestionInfoComponent } from './oracle/oracle-questions/question-info/question-info.component';
import { RunaComponent } from './oracle/reading/runa/runa.component';
import { SpiritAnimalsComponent } from './oracle/spirit-animals/spirit-animals.component';
import { DeitiesComponent } from './oracle/deities/deities.component';

// profile components
import { ProfileComponent } from './profile/profile/profile.component';
import { ProfileSpiritComponent } from './profile/profile-spirit/profile-spirit.component';
import { ProfileLibraryComponent } from './profile/profile-library/profile-library.component';
import { ProfileGoalsComponent } from './profile/profile-goals/profile-goals.component';
import { GoalStepsComponent } from './profile/goal-steps/goal-steps.component';
import { SidenavComponent } from './layout/sidenav/sidenav/sidenav.component';
import { OracleCardWisdomComponent } from './oracle/oracle-card-wisdom/oracle-card-wisdom.component';
import { AdminComponent } from './admin/admin/admin.component';
import { AdminOracleCardComponent } from './admin/admin-oracle-card/admin-oracle-card.component';
import { PageToolbarComponent } from './layout/page-toolbar/page-toolbar.component';
import { OracleButtonComponent } from './oracle/oracle-button/oracle-button.component';
import { DeleteReadingDialogComponent } from './oracle/reading/delete-reading-dialog/delete-reading-dialog.component';
import { BookComponent } from './quotes/book/book.component';
import { AccountComponent } from './profile/account/account.component';
import { ProfileMenuComponent } from './profile/profile/profile-menu/profile-menu.component';
import { AppRoutingModule } from './app-routing.module';
import { ProfileSettingsComponent } from './profile/profile-settings/profile-settings.component';
import { OracleComponent } from './oracle/oracle.component';
import { EmojiComponent } from './layout/emoji/emoji.component';
import { AboutComponent } from './about/about.component';


export function tokenGetter() {
  return localStorage.getItem('access_token');
}

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    ExploreComponent,
    ProfileComponent,
    QuotesListComponent,
    QuoteComponent,
    QuotesComponent,
    TarotComponent,
    RunesComponent,
    SpreadsComponent,
    OneCardComponent,
    ThreeCardComponent,
    StarSpreadComponent,
    CelticCrossComponent,
    SigninComponent,
    AuthorComponent,
    BreadcrumbComponent,
    TagsComponent,
    OracleQuestionsComponent,
    SpreadInfoComponent,
    QuotesActionsComponent,
    QuoteEditComponent,
    OracleActionsComponent,
    AuthorEditComponent,
    BookEditComponent,
    SpiritAnimalComponent,
    ProfileSpiritComponent,
    OracleReadingsComponent,
    ReadingInfoComponent,
    ReadingComponent,
    OneCardReadingComponent,
    ThreeCardReadingComponent,
    StarReadingComponent,
    CelticCrossReadingComponent,
    QuotesFromKeywordsComponent,
    QuoteFromReadingComponent,
    QuestionInfoComponent,
    RunaComponent,
    SpiritAnimalsComponent,
    DeitiesComponent,
    ProfileLibraryComponent,
    ProfileGoalsComponent,
    WorldComponent,
    ExploreComponent,
    MindComponent,
    GoalStepsComponent,
    SidenavComponent,
    WorldNavComponent,
    PostComponent,
    PostEditComponent,
    PostListComponent,
    CompassionComponent,
    OracleCardWisdomComponent,
    AdminComponent,
    AdminOracleCardComponent,
    PageToolbarComponent,
    OracleButtonComponent,
    DeleteReadingDialogComponent,
    SocialAuthComponent,
    BookComponent,
    AccountComponent,
    ProfileMenuComponent,
    ProfileSettingsComponent,
    OracleCardComponent,
    OracleComponent,
    EmojiComponent,
    AboutComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    DragDropModule,
    NgbModule,
    MatSidenavModule,
    MatExpansionModule,
    MatCardModule,
    MatToolbarModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatInputModule,
    MatDialogModule,
    MatSnackBarModule,
    MatChipsModule,
    MatDividerModule,
    MatMenuModule,
    MatIconModule,
    BrowserAnimationsModule,
    SocialLoginModule,
    JwtModule.forRoot({
      config: {
        authScheme: 'JWT ',
        tokenGetter: tokenGetter,
        whitelistedDomains: ['localhost:8000']
      }
    }),
    NgProgressModule.forRoot(),
    NgProgressModule,
    NgProgressHttpModule,
    NgDynamicBreadcrumbModule,
    NgxUiLoaderModule,
    ScrollToModule.forRoot(),
    AppRoutingModule
  ],
  bootstrap: [AppComponent],
  entryComponents: [DeleteReadingDialogComponent, SocialAuthComponent]
})
export class AppModule { }
