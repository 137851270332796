import { Component, OnInit } from '@angular/core';
import { MapService } from '../map.service';
import { ExploreService } from '../explore.service';

@Component({
  selector: 'app-world-nav',
  templateUrl: './world-nav.component.html',
  styleUrls: ['./world-nav.component.css']
})
export class WorldNavComponent implements OnInit {

  constructor(
    private mapService: MapService,
    private exploreService: ExploreService
  ) { }

  ngOnInit() {
  }

  moveToSiberianTiger() {
    this.mapService.moveToSiberianTiger();
  }

  moveToEgyptianPyramids() {
    this.mapService.moveToEgyptianPyramids();
  }

  moveToVincaCulture() {
    this.mapService.moveToVincaCulture();
  }

}
