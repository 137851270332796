import { Component, OnInit, OnChanges, Input, SimpleChanges } from '@angular/core';
import { QuoteService } from '../quote.service';

@Component({
  selector: 'app-quotes-from-keywords',
  templateUrl: './quotes-from-keywords.component.html',
  styleUrls: ['./quotes-from-keywords.component.css']
})
export class QuotesFromKeywordsComponent implements OnInit, OnChanges {

  @Input() sourceOfWisdom: string;
  @Input() keyword: any;
  @Input() uprightTags: any;
  quotes: any;

  constructor(
    private quoteService: QuoteService
  ) {

  }

  ngOnInit() {

    this.getQuotesFromTags();
    // this.getQuotesFromKeywords();

  }

  ngOnChanges() {

    this.getQuotesFromTags();
    // this.getQuotesFromKeywords();

  }

  getQuotesFromTags() {

    let tags = '';
    this.uprightTags.forEach(function (tag) {
      tags += tag.id + ',';
    });

    tags = tags.slice(0, -1);
    this.quotes = null;
    if (tags !== '') {
      this.quoteService.getQuotes(tags)
        .subscribe((data) => {
          this.quotes = data;
        });
    }

  }

  getQuotesFromKeywords() {

    let tags = '';
    this.keyword.forEach(function (k) {

      if (k.tag.length > 1) {

        k.tag.forEach(function (t) {

          tags += t + ',';

        });
      } else {
        tags += k.tag + ',';
      }


    });
    tags = tags.slice(0, -1);

    this.quotes = null;
    if (tags !== '') {

      this.quoteService.getQuotes(tags)
        .subscribe((data) => {
          this.quotes = data;
        });

    }

  }

  loadMoreQuotes(url: string) {

    this.quoteService.loadMoreQuotes(url)
      .subscribe((data) => {
        this.quotes.next = data['next'];
        data['results'].forEach(quote => {
          this.quotes.results.push(quote);
        });
      });
  }

}
