import { Component, OnInit, Input } from '@angular/core';
import { OracleService } from '../oracle.service';

@Component({
  selector: 'app-reading-info',
  templateUrl: './reading-info.component.html',
  styleUrls: ['./reading-info.component.css']
})
export class ReadingInfoComponent implements OnInit {

  @Input() reading: any;

  constructor(
    private oracleService: OracleService,
  ) { }

  ngOnInit() {
  }

  ShowReading(reading: any) {
    this.oracleService.reading = reading;
  }

}
