import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../auth/auth.service';
import { ExploreService } from '../explore.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-explore',
  templateUrl: './explore.component.html',
  styleUrls: ['./explore.component.css']
})
export class ExploreComponent implements OnInit {
  constructor(
    public authService: AuthenticationService,
    public exploreService: ExploreService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    const url = this.activatedRoute.snapshot.url.toString();

    switch (url) {
      case '':
      case 'explore':
        this.exploreService.showQuotes = true;
        this.exploreService.showWorld = false;
        this.exploreService.showMind = false;
        this.exploreService.showCompassion = false;
        break;
      case 'wisdom,quotes': {
        this.exploreService.showQuotes = true;
        this.exploreService.showWorld = false;
        this.exploreService.showMind = false;
        this.exploreService.showCompassion = false;
        break;
      }
      case 'explore,world': {
        this.exploreService.showQuotes = false;
        this.exploreService.showWorld = true;
        this.exploreService.showMind = false;
        this.exploreService.showCompassion = false;
        break;
      }
      case 'explore,ideas': {
        this.exploreService.showQuotes = false;
        this.exploreService.showWorld = false;
        this.exploreService.showMind = true;
        this.exploreService.showEditPost = false;
        this.exploreService.showCompassion = false;
        break;
      }
      case 'explore,ideas,add': {
        this.exploreService.showQuotes = false;
        this.exploreService.showWorld = false;
        this.exploreService.showMind = true;
        this.exploreService.showEditPost = true;
        this.exploreService.showCompassion = false;
        break;
      }
      case 'explore,compassion': {
        this.exploreService.showQuotes = false;
        this.exploreService.showWorld = false;
        this.exploreService.showMind = false;
        this.exploreService.showCompassion = true;
        break;
      }
      default: {
        break;
      }
    }
  }
}
