import { Component, OnInit } from '@angular/core';
import { OracleService } from '../oracle.service';

@Component({
  selector: 'app-oracle-readings',
  templateUrl: './oracle-readings.component.html',
  styleUrls: ['./oracle-readings.component.css']
})
export class OracleReadingsComponent implements OnInit {

  quotes: any;
  user: any;
  oracleReadings: any;
  reading: any;

  oracleDecks: any;
  deckSpreads: any;
  keywords: any;

  selectedDecks: any;
  selectedSpreads: any;
  selectedKeywords: any;

  deckIds: string;
  spreadIds: string;
  keywordIds: string;

  constructor(
    private oracleService: OracleService
  ) { }

  ngOnInit() {

    this.deckIds = 'all';
    this.spreadIds = 'all';
    this.keywordIds = 'all';
    // this.getOracleDecks();
    // this.getDeckSpreads();
    // this.getQuestionKeywords();
    this.getOracleReadings();

  }

  getOracleDecks() {

    this.oracleService.getOracleDecks()
      .subscribe(oracleDecks => {

        this.oracleDecks = oracleDecks;
        // this.selectedDecks = oracleDecks[0];

      });

  }

  getDeckSpreads() {

    this.oracleService.getDeckSpreads()
      .subscribe(deckSpreads => {

        this.deckSpreads = deckSpreads;
        this.selectedSpreads = deckSpreads[0];

      });

  }

  getQuestionKeywords() {

    this.oracleService.getQuestionKeywords()
      .subscribe(keywords => {

        this.keywords = keywords;

      });

  }

  getOracleReadings() {

    this.oracleService.getOracleReadings()
      .subscribe(oracleReadings => this.oracleReadings = oracleReadings);

  }

  filterChange(objects: any, filterType: string) {

    let ids = '';

    for (const object of objects) {
      ids += object.id + ',';
    }
    ids = ids.substring(0, ids.length - 1);

    if (ids === '') {
      ids = 'all';
    }

    switch (filterType) {
      case 'CHANGE_DECKS': {
        this.deckIds = ids;
        break;
      }
      case 'CHANGE_SPREADS': {
        this.spreadIds = ids;
        break;
      }
      case 'CHANGE_KEYWORDS': {
        this.keywordIds = ids;
        break;
      }
      default: {
        break;
      }
    }

    this.filterOracleReadings(this.deckIds, this.spreadIds, this.keywordIds);

    console.log('IDS');
    console.log('deckIds = ' + this.deckIds);
    console.log('spreadIds = ' + this.spreadIds);
    console.log('keywordIds = ' + this.keywordIds);

  }

  onChangeDecks() {

    this.filterChange(this.selectedDecks, 'CHANGE_DECKS');

  }

  onChangeSpreads() {

    this.filterChange(this.selectedSpreads, 'CHANGE_SPREADS');

  }

  onChangeKeywords() {

    this.filterChange(this.selectedKeywords, 'CHANGE_KEYWORDS');

  }

  filterOracleReadings(deckIds: string, spreadIds: string, keywordIds: string) {

    this.oracleService.filterOracleReadings(deckIds, spreadIds, keywordIds)
      .subscribe(oracleReadings => this.oracleReadings = oracleReadings);

  }

}
