import { Component, OnInit } from '@angular/core';
import { TagService } from '../tag.service';
import { ActivatedRoute } from '@angular/router';
import { QuoteService } from '../quote.service';

@Component({
  selector: 'app-quotes',
  templateUrl: './quotes.component.html',
  styleUrls: ['./quotes.component.css']
})
export class QuotesComponent implements OnInit {

  private tags: any;

  constructor(
    private tagService: TagService,
    private route: ActivatedRoute,
    private quoteService: QuoteService
  ) { }

  ngOnInit() {

    this.route.params.subscribe(params => {
      this.tags = params['tag'];

      if (this.tags == null) {
        this.tagService.selectedTags = [];
      }
    });

  }

}
