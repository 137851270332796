import { Component, OnInit } from '@angular/core';
import { ProfileService } from '../profile.service';
import { OracleService } from '../../oracle/oracle.service';
import { UserService } from '../user.service';
import { AuthenticationService } from '../../auth/auth.service';

@Component({
  selector: 'app-profile-spirit',
  templateUrl: './profile-spirit.component.html',
  styleUrls: ['./profile-spirit.component.css']
})
export class ProfileSpiritComponent implements OnInit {

  user: any;
  spiritAnimalCards: any;

  goalSteps: any;
  userGoal: any;

  oracleReadings: any;
  oracleInfo = {
    readingsNumber: null,
  };


  constructor(
    public profileService: ProfileService,
    public oracleService: OracleService,
    public userService: UserService,
    public authService: AuthenticationService
  ) { }

  ngOnInit() {

    this.getUserInfo();
    this.getUserGoal();
    this.getGoalSteps();
    this.getOracleReadings();

  }

  // TODO: create single method to replace getUserGoal and getGoalSteps for getting numbeor of steps
  getUserGoal() {

    this.profileService.getUserGoal()
      .subscribe((data) => {
        this.userGoal = data;
      });
  }

  getGoalSteps() {

    this.profileService.getGoalSteps()
      .subscribe((data) => {

        this.goalSteps = data;

        this.profileService.currentSteps = this.goalSteps.filter(
          step => step.stage.code === 'CURRENT_STEP'
        );

        this.profileService.futureSteps = this.goalSteps.filter(
          step => step.stage.code === 'FUTURE_STEP'
        );

        this.profileService.completedSteps = this.goalSteps.filter(
          step => step.stage.code === 'COMPLETED_STEP'
        );

      });
  }

  // TODO: create method to replace getOracleReadings for getting oracle infos
  getOracleReadings() {

    this.oracleService.getOracleReadings()
      .subscribe(oracleReadings => {

        this.oracleReadings = oracleReadings;

        this.oracleInfo.readingsNumber = this.oracleReadings.length;

      });
  }

  getUserInfo() {

    const userId = window.localStorage.getItem('user_id');
    this.userService.getUserById(userId)
      .subscribe(user => this.user = user);
  }

  signout() {
    this.authService.logout();
  }

}
