import { Component } from '@angular/core';
import { AuthenticationService } from '../../auth/auth.service';

@Component({
    selector: 'app-nav-menu',
    templateUrl: './nav-menu.component.html',
    styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent {

    constructor(private authService: AuthenticationService) { }

}
