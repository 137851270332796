import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './auth/auth-guard.service';
import { ExploreComponent } from './explore/explore/explore.component';
import { SigninComponent } from './auth/signin/signin.component';
import { SpiritAnimalComponent } from './oracle/spirit-animal/spirit-animal.component';
import { OracleCardWisdomComponent } from './oracle/oracle-card-wisdom/oracle-card-wisdom.component';
import { QuestionInfoComponent } from './oracle/oracle-questions/question-info/question-info.component';
import { TarotComponent } from './oracle/tarot/tarot.component';
import { RunesComponent } from './oracle/runes/runes.component';
import { QuotesListComponent } from './quotes/quotes-list/quotes-list.component';
import { QuoteEditComponent } from './quotes/quote-edit/quote-edit.component';
import { ProfileComponent } from './profile/profile/profile.component';
import { ReadingComponent } from './oracle/reading/reading.component';
import { ProfileSettingsComponent } from './profile/profile-settings/profile-settings.component';
import { AuthorComponent } from './quotes/author/author.component';
import { BookComponent } from './quotes/book/book.component';
import { AdminComponent } from './admin/admin/admin.component';
import { AdminOracleCardComponent } from './admin/admin-oracle-card/admin-oracle-card.component';
import { OracleComponent } from './oracle/oracle.component';

import { AuthServiceConfig } from 'angularx-social-login';
import { GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptorService } from './auth/auth-interceptor.service';
import { AboutComponent } from './about/about.component';

const config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider('239589625094-eg1k2musjbahivqtmrvqk22b4bubl183.apps.googleusercontent.com')
  },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider('779331612458732')
  }
]);

export function provideConfig() {
  return config;
}

const routes: Routes = [
  { path: '', redirectTo: 'wisdom/spiritanimals/oracle', pathMatch: 'full' },
  {
    path: 'explore', component: ExploreComponent,
    data: {
      breadcrumb: 'explore'
    }
  },

  { path: 'sigin', component: SigninComponent },

  {
    path: 'wisdom/oracle',
    component: OracleComponent,
    data: {
      title: 'oracle',
      breadcrumb: [
        {
          label: 'Oracle',
          url: ''
        }
      ]
    },
  },

  {
    path: 'wisdom/spiritanimals',
    component: SpiritAnimalComponent,
    data: {
      title: 'spirit animals',
      breadcrumb: [
        {
          label: 'Spirit Animals',
          url: ''
        }
      ]
    },
  },
  {
    path: 'wisdom/spiritanimals/:id/:name',
    component: OracleCardWisdomComponent,
    data: {
      title: 'spirit animals',
      breadcrumb: [
        {
          label: 'Spirit Animals',
          url: 'wisdom/spiritanimals'
        },
        {
          label: '{{name}}',
          url: ''
        }
      ]
    },
  },
  {
    path: 'wisdom/spiritanimals/oracle',
    component: QuestionInfoComponent,
    data: {
      title: 'oracle',
      breadcrumb: [
        {
          label: 'Spirit Animals',
          url: 'wisdom/spiritanimals'
        },
        {
          label: 'Oracle',
          url: ''
        }
      ]
    },
  },
  {
    path: 'wisdom/tarot',
    component: TarotComponent,
    data: {
      title: 'tarot',
      breadcrumb: [
        {
          label: 'Tarot',
          url: ''
        }
      ]
    },
  },
  {
    path: 'wisdom/tarot/oracle',
    component: QuestionInfoComponent,
    data: {
      title: 'oracle',
      breadcrumb: [
        {
          label: 'Tarot',
          url: 'wisdom/tarot'
        },
        {
          label: 'Oracle',
          url: ''
        }
      ]
    },
  },
  {
    path: 'wisdom/tarot/:id/:name',
    component: OracleCardWisdomComponent,
    data: {
      title: 'tarot',
      breadcrumb: [
        {
          label: 'Tarot',
          url: 'wisdom/tarot'
        },
        {
          label: '{{name}}',
          url: ''
        }
      ]
    },
  },
  {
    path: 'wisdom/runes',
    component: RunesComponent,
    data: {
      title: 'runes',
      breadcrumb: [
        {
          label: 'Runes',
          url: ''
        }
      ]
    },
  },
  {
    path: 'wisdom/runes/oracle',
    component: QuestionInfoComponent,
    data: {
      title: 'oracle',
      breadcrumb: [
        {
          label: 'Runes',
          url: 'wisdom/runes'
        },
        {
          label: 'Oracle',
          url: ''
        }
      ]
    },
  },

  {
    path: 'wisdom/runes/:id/:name',
    component: OracleCardWisdomComponent,
    data: {
      title: 'runes',
      breadcrumb: [
        {
          label: 'Runes',
          url: 'wisdom/runes'
        },
        {
          label: '{{name}}',
          url: ''
        }
      ]
    },
  },
  {
    path: 'wisdom/quotes',
    component: QuotesListComponent,
    data: {
      title: 'quotes',
      breadcrumb: [
        {
          label: 'Quotes',
          url: ''
        }
      ]
    },
  },
  {
    path: 'wisdom/quotes/:tag',
    component: QuotesListComponent,
    data: {
      title: 'quotes',
      breadcrumb: [
        {
          label: 'Quotes',
          url: ''
        }
      ]
    },
  },
  {
    path: 'wisdom/newquotes',
    component: QuoteEditComponent,
    data: {
      title: 'quote edit',
      breadcrumb: [
        {
          label: 'Quote edit',
          url: ''
        }
      ]
    },
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuardService],
    data: {
      title: 'profile',
      breadcrumb: [
        {
          label: 'Profile',
          url: ''
        }
      ]
    },
  },
  {
    path: 'profile/oracle/:id',
    component: ReadingComponent,
    canActivate: [AuthGuardService],
    data: {
      title: 'oracle',
      breadcrumb: [
        {
          label: 'Profile',
          url: 'profile'
        },
        {
          label: 'Oracle',
          url: ''
        }
      ]
    },
  },
  {
    path: 'profile/settings',
    component: ProfileSettingsComponent,
    canActivate: [AuthGuardService],
    data: {
      title: 'settings',
      breadcrumb: [
        {
          label: 'Profile',
          url: 'profile'
        },
        {
          label: 'Settings',
          url: ''
        }
      ]
    },
  },
  {
    path: 'wisdom/quotes/author/:id/:fullname',
    component: AuthorComponent,
    data: {
      title: 'author',
      breadcrumb: [
        {
          label: 'Quotes',
          url: 'wisdom/quotes'
        },
        {
          label: '{{fullname}}',
          url: ''
        }
      ]
    },
  },
  {
    path: 'wisdom/quotes/book/:id/:title',
    component: BookComponent,
    data: {
      title: 'book',
      breadcrumb: [
        {
          label: 'Quotes',
          url: 'wisdom/quotes'
        },
        {
          label: '{{title}}',
          url: ''
        }
      ]
    },
  },
  {
    path: 'signin',
    component: SigninComponent,
    data: {
      title: 'sign in',
      breadcrumb: [
        {
          label: 'Sign in',
          url: ''
        }
      ]
    },
  },
  {
    path: 'about',
    component: AboutComponent,
    data: {
      title: 'about',
      breadcrumb: [
        {
          label: 'About',
          url: ''
        }
      ]
    },
  },
  { path: 'profile/goals', component: ProfileComponent, canActivate: [AuthGuardService] },
  { path: 'explore/world', component: ExploreComponent },
  { path: 'explore/ideas', component: ExploreComponent, canActivate: [AuthGuardService] },
  { path: 'explore/ideas/:idea', component: ExploreComponent, canActivate: [AuthGuardService] },
  { path: 'explore/compassion', component: ExploreComponent, canActivate: [AuthGuardService] },
  { path: 'tarot', component: TarotComponent, canActivate: [AuthGuardService] },
  { path: 'runes', component: RunesComponent },
  { path: 'admin', component: AdminComponent, canActivate: [AuthGuardService] },
  { path: 'admin/oraclecard', component: AdminOracleCardComponent, canActivate: [AuthGuardService] },
];



@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    AuthGuardService,
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    }
  ],
})
export class AppRoutingModule { }
