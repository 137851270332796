import { Component, OnInit, Input } from '@angular/core';
import { SpreadInfo } from 'src/app/oracle/spread-info';

@Component({
  selector: 'app-spread-info',
  templateUrl: './spread-info.component.html',
  styleUrls: ['./spread-info.component.css']
})
export class SpreadInfoComponent implements OnInit {

  @Input() spreadInfo: SpreadInfo;

  constructor() { }

  ngOnInit() {
  }

}
