import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { OracleReading } from './oracle-reading';
import { SpreadInfo } from './spread-info';
import { OracleCard } from './oracle-card/oracle-card';
import { ConfigService } from './config.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OracleService {

  reading: any;
  spreadCardImage: string;
  selectedDeckCode: string;
  API_URL = environment.API_URL;
  private httpOptions: any;
  spiritAnimalCards: any;
  tarotCards: any;
  runes: any;

  constructor(
    private http: HttpClient,
    private configService: ConfigService
  ) {

    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    this.selectedDeckCode = 'SPIRIT_ANIMALS';

    this.getOracleCardsByOracleId('3')
      .subscribe((data) => {
        this.spiritAnimalCards = data;
      });

    this.getOracleCardsByOracleId('1')
      .subscribe((data) => {
        this.tarotCards = data;
      });

    this.getOracleCardsByOracleId('2')
      .subscribe((data) => {
        this.runes = data;
      });
  }

  getOracleCards(oracleId: string, randomNumbers: number[]) {

    let cardNumbers = '';
    randomNumbers.forEach(function (num) {
      cardNumbers += num + ',';
    });
    cardNumbers = cardNumbers.substring(0, cardNumbers.length - 1);

    return this.http.get(`${this.API_URL}/oraclecards/` + oracleId + '/' + cardNumbers);

  }

  orderOracleCards(data, randomNumbers) {
    const pickedCards: any[] = [];
    // sort cards from db query to be in same order as random numbers
    randomNumbers.forEach(function (num) {
      pickedCards.push(data.find(card => card.number === num));
    });
    return pickedCards;
  }

  saveOracleReading(pickedCards: any, oracleId: number, spreadId: number, question: string, keywords: any) {

    const oracleReading: OracleReading = new OracleReading();
    oracleReading.reading = '';

    pickedCards.forEach(function (number) {
      if (number !== undefined) {
        oracleReading.reading += number + ',';
      }
    });
    oracleReading.reading = oracleReading.reading.substring(0, oracleReading.reading.length - 1);

    oracleReading.user = Number(window.localStorage.getItem('user_id'));
    oracleReading.oracle = oracleId;
    oracleReading.spread = spreadId;
    oracleReading.question = question;

    // if (keywords != null) {
    //   keywords.forEach(function (keyword) {
    //     if (keyword.id !== undefined) {
    //       oracleReading.question_keyword.push(keyword.id);
    //     }
    //   });
    // }

    this.http.post<any>(`${this.API_URL}/oraclereadingcreate/`, oracleReading, this.httpOptions).subscribe(
      response => console.log(response),
      err => console.log(err)
    );

  }

  deleteOracleReading(id: string) {
    return this.http.delete<any>(`${this.API_URL}/deleteoraclereading/` + id, this.httpOptions);
  }

  getOracleReadings() {
    return this.http.get(`${this.API_URL}/oraclereadings/` + window.localStorage.getItem('user_id'));
  }

  getOracleReadingById(oracleReadingId: string) {
    return this.http.get(`${this.API_URL}/oraclereadingbyid/` + oracleReadingId + '/' + window.localStorage.getItem('user_id'));
  }


  filterOracleReadings(deckIds: string, spreadIds: string, keywordIds: string) {
    return this.http.get(`${this.API_URL}/filteroraclereadings/` + window.localStorage.getItem('user_id')
      + '/' + deckIds + '/' + spreadIds + '/' + keywordIds);
  }

  getRandomNumbers(oracleCode: string, numberOfCards: number): number[] {

    // oracleCardsNumber is number of deck cards minus 1
    const oracleCardsNumber: number = this.configService.getOracleCardsNumber(oracleCode);

    const randomNumbers: number[] = [];
    if (numberOfCards === 1) {
      randomNumbers[0] = Math.floor((Math.random() * oracleCardsNumber) + 0);
    } else {
      while (randomNumbers.length < numberOfCards) {
        const randomnumber = Math.floor(Math.random() * oracleCardsNumber) + 0;
        if (randomNumbers.indexOf(randomnumber) > -1) {
          continue;
        }
        randomNumbers[randomNumbers.length] = randomnumber;
      }
    }
    return randomNumbers;

  }

  calculateSpreadInfo(pickedCards: any): SpreadInfo {

    const spreadInfo: SpreadInfo = new SpreadInfo();

    pickedCards.forEach(function (card) {
      if (card.type.code === 'MAJOR_ARCANA') {
        spreadInfo.numberOfMajorArcana++;
      } else if (card.type.code === 'MINOR_ARCANA') {
        spreadInfo.numberOfMinorArcana++;
      }
    });

    return spreadInfo;

  }

  getOracleDecks() {
    return this.http.get(`${this.API_URL}/oracledecks/`);
  }

  getDeckSpreads() {
    return this.http.get(`${this.API_URL}/deckspreads/`);
  }

  getQuestionKeywords() {
    return this.http.get(`${this.API_URL}/questionkeywords/` + window.localStorage.getItem('user_id'));
  }

  createQuestionKeyword(name: string) {

    const keyword = {
      user: window.localStorage.getItem('user_id'),
      name: ''
    };
    keyword.name = name;

    this.http.post<any>(`${this.API_URL}/createquestionkeyword/`, keyword, this.httpOptions).subscribe(
      response => console.log(response),
      err => console.log(err)
    );

  }

  getOracleCardsByOracleId(oracleId: string) {

    return this.http.get(`${this.API_URL}/oraclecardsbyoracleid/` + oracleId);

  }

  getOracleCardById(oracleCardId: string) {

    return this.http.get(`${this.API_URL}/oraclecardbyid/` + oracleCardId);

  }

  updateOracleCard(oracleCard: OracleCard) {

    const uprightKeywords = oracleCard.upright_keywords.length !== 0 ? oracleCard.upright_keywords : ',';
    const reversedKeywords = oracleCard.reversed_keywords.length !== 0 ? oracleCard.reversed_keywords : ',';
    const uprightTags = oracleCard.upright_tags.length !== 0 ? oracleCard.upright_tags : ',';
    const reversedTags = oracleCard.reversed_tags.length !== 0 ? oracleCard.reversed_tags : ',';

    return this.http.patch<any>(`${this.API_URL}/updateoraclecard/` + oracleCard.id
      + '/' + uprightKeywords + '/' + reversedKeywords + '/' + uprightTags + '/' + reversedTags, this.httpOptions);

  }


}
