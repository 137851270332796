import { Component, OnInit } from '@angular/core';
import { Tarot } from '../../tarot';
import { OracleService } from '../../oracle.service';
import { SpreadInfo } from 'src/app/oracle/spread-info';

@Component({
  selector: 'app-three-card',
  templateUrl: './three-card.component.html',
  styleUrls: ['./three-card.component.css']
})
export class ThreeCardComponent implements OnInit {

  pickedCards: Tarot[];
  randomNumbers: number[];
  spreadInfo: SpreadInfo;

  constructor(
    public oracleService: OracleService
  ) { }

  ngOnInit() {
  }

}
