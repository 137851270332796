import { Component, OnInit } from '@angular/core';
import { OracleService } from '../../oracle/oracle.service';
import { AdminService } from '../admin.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

  oracleCards: any;
  selectedCard: any;
  selected: any = '2';

  constructor(
    private router: Router,
    private oracleService: OracleService,
    private adminService: AdminService
  ) { }

  ngOnInit() {
    this.adminService.editOracleCard = null;
    this.getOracleCards();
  }

  getOracleCards() {

    this.oracleService.getOracleCardsByOracleId('2')
      .subscribe((data) => {
        this.oracleCards = data;
      });
  }

  changeOracleCards(oracleId: string) {

    this.oracleCards = null;
    this.oracleService.getOracleCardsByOracleId(oracleId)
      .subscribe((data) => {
        this.oracleCards = data;
      });
  }

  selectOracleCard(oracleCard: any) {

    this.adminService.editOracleCard = oracleCard;
    this.router.navigate(['/admin/oraclecard']);

  }

}
