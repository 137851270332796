import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthenticationService } from './auth.service';

@Injectable()
export class AuthGuardService implements CanActivate {

    constructor(private authService: AuthenticationService) { }

    canActivate(): boolean {

        return this.authService.isLoggedIn();

    }
}

