import { Component, OnInit } from '@angular/core';
import { OracleService } from '../oracle.service';
import { Tarot } from 'src/app/oracle/tarot';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';

@Component({
  selector: 'app-deities',
  templateUrl: './deities.component.html',
  styleUrls: ['./deities.component.css']
})
export class DeitiesComponent implements OnInit {

  cardNumbers: number[];
  pickedCards: Tarot[];
  selectedCard: any;
  scrollToConfig: ScrollToConfigOptions;

  selectCardOne: boolean;
  selectCardTwo: boolean;
  selectCardThree: boolean;
  selectCardSignificator: boolean;
  selectCardFour: boolean;
  selectCardFive: boolean;

  constructor(
    private oracleService: OracleService,
    private scrollToService: ScrollToService
  ) {

    this.pickedCards = [];

    this.selectCardOne = false;
    this.selectCardTwo = false;
    this.selectCardThree = false;
    this.selectCardSignificator = false;
    this.selectCardFour = false;
    this.selectCardFive = false;

    this.scrollToConfig = {
      target: 'scrollToDestination'
    };

  }

  ngOnInit() {

    this.theStarSpread();

  }

  theStarSpread() {

    // const oracleId = '4'; // slavic deities
    // const oracleId = '5'; // ancient egyptian deities
    // const oracleId = '6'; // greek deities
    const oracleId = '7'; // norse deities

    // this.cardNumbers = this.oracleService.getRandomNumbers('SLAVIC_DEITIES', 6);
    // this.cardNumbers = this.oracleService.getRandomNumbers('SPIRIT_ANIMALS', 6);
    this.cardNumbers = [6, 7, 8, 9, 10, 11];


    console.log(this.cardNumbers);

    this.oracleService.getOracleCards(oracleId, this.cardNumbers)

      .subscribe((data) => {

        this.pickedCards = this.oracleService.orderOracleCards(data, this.cardNumbers);
        // this.spreadInfo = this.oracleService.calculateSpreadInfo(this.pickedCards);

      });

  }

  selectCard(card, selectCard) {

    this.selectedCard = card;
    this.scrollToService.scrollTo(this.scrollToConfig);

    switch (selectCard) {
      case 'selectCardOne': {
        this.selectCardOne = true;
        this.selectCardTwo = false;
        this.selectCardThree = false;
        this.selectCardSignificator = false;
        this.selectCardFour = false;
        this.selectCardFive = false;
        break;
      }
      case 'selectCardTwo': {
        this.selectCardOne = false;
        this.selectCardTwo = true;
        this.selectCardThree = false;
        this.selectCardSignificator = false;
        this.selectCardFour = false;
        this.selectCardFive = false;
        break;
      }
      case 'selectCardThree': {
        this.selectCardOne = false;
        this.selectCardTwo = false;
        this.selectCardThree = true;
        this.selectCardSignificator = false;
        this.selectCardFour = false;
        this.selectCardFive = false;
        break;
      }
      case 'selectCardSignificator': {
        this.selectCardOne = false;
        this.selectCardTwo = false;
        this.selectCardThree = false;
        this.selectCardSignificator = true;
        this.selectCardFour = false;
        this.selectCardFive = false;
        break;
      }
      case 'selectCardFour': {
        this.selectCardOne = false;
        this.selectCardTwo = false;
        this.selectCardThree = false;
        this.selectCardSignificator = false;
        this.selectCardFour = true;
        this.selectCardFive = false;
        break;
      }
      case 'selectCardFive': {
        this.selectCardOne = false;
        this.selectCardTwo = false;
        this.selectCardThree = false;
        this.selectCardSignificator = false;
        this.selectCardFour = false;
        this.selectCardFive = true;
        break;
      }
      default: {
        break;
      }
    }

  }

}
