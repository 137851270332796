import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

export class Tag {
  id: number;
  name: string;
}
@Injectable({
  providedIn: 'root'
})
export class TagService {
  selectedTags: Tag[];

  constructor(private router: Router) {
    this.selectedTags = [];
  }

  addTag(id, name): void {
    const tag = new Tag();
    tag.id = id;
    tag.name = name;

    const findTag = this.selectedTags.find(function (item) {
      return item.id === tag.id;
    });

    if (!findTag) {
      this.selectedTags.push(tag);
      this.selectedTagsUrl();
    }
  }

  removeTag(id): void {
    const findTag = this.selectedTags.find(function (item) {
      return item.id === id;
    });

    if (findTag) {
      const removeIndex = this.selectedTags
        .map(function (item) {
          return item.id;
        })
        .indexOf(findTag.id);

      if (removeIndex !== -1) {
        this.selectedTags.splice(removeIndex, 1);
        this.selectedTagsUrl();
      }
    }
  }

  selectedTagsUrl() {
    let selectedTagsUrl = '';

    for (const selectedTag of this.selectedTags) {
      if (selectedTag.id) {
        selectedTagsUrl += selectedTag.id + ',';
      }
    }
    selectedTagsUrl = selectedTagsUrl.substring(0, selectedTagsUrl.length - 1);

    // for fraction of second make selectedTags empty string
    // that is becouse of ng-select from quote-list.component
    // TODO: maybe there is some better way but this is solution for now

    const helpTags = this.selectedTags;
    this.selectedTags = [];
    this.delay(5).then(any => {
      this.selectedTags = helpTags;
      this.router.navigate(['/wisdom/quotes/' + selectedTagsUrl]);
    });
  }

  async delay(ms: number) {
    await new Promise(resolve => setTimeout(() => resolve(), ms));
  }
}
