import { Component, OnInit } from '@angular/core';
import { ProfileService } from '../profile.service';

@Component({
  selector: 'app-profile-goals',
  templateUrl: './profile-goals.component.html',
  styleUrls: ['./profile-goals.component.css']
})
export class ProfileGoalsComponent implements OnInit {

  userGoal: any;

  constructor(
    private profileService: ProfileService
  ) { }

  ngOnInit() {

    this.getUserGoal();

  }

  getUserGoal() {

    this.profileService.getUserGoal()
      .subscribe((data) => {
        this.userGoal = data;
      });
  }

}
