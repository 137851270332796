import { Component, OnInit } from '@angular/core';
import { OracleService } from '../../oracle.service';
import { Tarot } from '../../tarot';
import { SpreadInfo } from 'src/app/oracle/spread-info';

@Component({
  selector: 'app-one-card',
  templateUrl: './one-card.component.html',
  styleUrls: ['./one-card.component.css']
})
export class OneCardComponent implements OnInit {

  pickedCards: Tarot[];
  randomNumbers: number[];
  spreadInfo: SpreadInfo;

  constructor(
    public oracleService: OracleService
  ) { }

  ngOnInit() {
    this.pickedCards = [];
  }

}
