import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WisdomService {

  selectedOracleCard: any;
  numOfImagesLoaded: number;

  constructor() { }
}
