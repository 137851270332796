import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LibraryService {

  API_URL = environment.API_URL;
  private httpOptions: any;

  constructor(
    private http: HttpClient
  ) {

    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

  }

  getUserLibrary(userId: string) {

    return this.http.get(`${this.API_URL}/userlibrary/` + userId);

  }
}
