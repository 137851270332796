import { Component, OnInit } from '@angular/core';
import { OracleService } from '../oracle.service';
@Component({
  selector: 'app-oracle-actions',
  templateUrl: './oracle-actions.component.html',
  styleUrls: ['./oracle-actions.component.css']
})
export class OracleActionsComponent implements OnInit {

  constructor(
    private oracleService: OracleService
  ) { }

  ngOnInit() {
  }

}
