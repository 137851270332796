import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Post } from './post/post';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ExploreService {
  API_URL = environment.API_URL;
  private httpOptions: any;

  showProfile: boolean;
  showQuotes: boolean;
  showWorld: boolean;
  showMind: boolean;
  showCompassion: boolean;
  showEditPost: boolean;
  selectedIdeas: any[];

  constructor(private http: HttpClient, private router: Router) {
    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    this.selectedIdeas = [];
    this.showProfile = false;
    this.showQuotes = false;
    this.showWorld = false;
    this.showMind = false;
    this.showCompassion = false;
    this.showEditPost = false;
  }

  savePost(post: Post) {
    this.http
      .post<Post>(`${this.API_URL}/savepost/`, post, this.httpOptions)
      .subscribe(
        response => {
          // console.log(response);
          this.router.navigate(['/explore/ideas/']);
        },
        err => console.log(err)
      );
  }

  getIdeas() {
    return this.http.get(`${this.API_URL}/getideas/`);
  }

  /**
   * get all connected ideas
   */
  getIdeaAndConnections(ideaIds: string) {
    return this.http.get(`${this.API_URL}/ideaandconnections/` + ideaIds);
  }

  /**
   * get connected ideas (groups of ideas)
   */
  getConnectedIdeas(ideaIds: string) {
    return this.http.get(`${this.API_URL}/connectedideas/` + ideaIds);
  }

  /**
   * get posts
   */
  getPosts() {
    return this.http.get(`${this.API_URL}/getposts/`);
  }

  getPostsByIdeaId(ideaIds: string) {
    return this.http.get(`${this.API_URL}/getpostsbyidea/` + ideaIds);
  }

  selectedIdeasUrl() {
    let selectedIdeasUrl = '';
    for (const selectedIdea of this.selectedIdeas) {
      if (
        selectedIdea.connected_ideas !== null &&
        selectedIdea.connected_ideas.length > 0
      ) {
        for (const ideaId of selectedIdea.connected_ideas) {
          if (ideaId) {
            selectedIdeasUrl += ideaId + ',';
          }
        }
      } else {
        if (selectedIdea.id) {
          selectedIdeasUrl += selectedIdea.id + ',';
        }
      }
    }
    selectedIdeasUrl = selectedIdeasUrl.substring(
      0,
      selectedIdeasUrl.length - 1
    );

    // for fraction of second make selectedTags empty string
    // that is becouse of ng-select from quote-list.component
    // TODO: maybe there is some better way but this is solution for now
    const helpIdeas = this.selectedIdeas;
    this.selectedIdeas = [];
    this.delay(5).then(any => {
      this.selectedIdeas = helpIdeas;
      this.router.navigate(['/explore/ideas/' + selectedIdeasUrl]);
    });
  }

  async delay(ms: number) {
    await new Promise(resolve => setTimeout(() => resolve(), ms));
  }
}
