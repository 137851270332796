import { Component, OnInit } from '@angular/core';
import { OracleService } from '../../oracle.service';
import { Tarot } from 'src/app/oracle/tarot';
import { ScrollToService, ScrollToConfigOptions } from '@nicky-lenaers/ngx-scroll-to';

@Component({
  selector: 'app-celtic-cross-reading',
  templateUrl: './celtic-cross-reading.component.html',
  styleUrls: ['./celtic-cross-reading.component.css']
})
export class CelticCrossReadingComponent implements OnInit {

  cardNumbers: number[];
  pickedCards: Tarot[];
  selectedCard: any;
  scrollToConfig: ScrollToConfigOptions;

  selectCardOne: boolean;
  selectCardTwo: boolean;
  selectCardThree: boolean;
  selectCardFour: boolean;
  selectCardFive: boolean;
  selectCardSix: boolean;
  selectCardSeven: boolean;
  selectCardEight: boolean;
  selectCardNine: boolean;
  selectCardTen: boolean;

  constructor(
    private oracleService: OracleService,
    private scrollToService: ScrollToService
  ) {

    this.selectCardOne = false;
    this.selectCardTwo = false;
    this.selectCardThree = false;
    this.selectCardFour = false;
    this.selectCardFive = false;
    this.selectCardSix = false;
    this.selectCardSeven = false;
    this.selectCardEight = false;
    this.selectCardNine = false;
    this.selectCardTen = false;

    this.scrollToConfig = {
      target: 'scrollToDestination'
    };

  }

  ngOnInit() {

    this.celtiCrossSpread();

  }

  celtiCrossSpread() {

    const oracleId = this.oracleService.reading.oracle.id;
    const reading = this.oracleService.reading.reading;
    this.cardNumbers = Array.from(reading.split(',').map(Number));

    this.oracleService.getOracleCards(oracleId, this.cardNumbers)

      .subscribe((data) => {

        this.pickedCards = this.oracleService.orderOracleCards(data, this.cardNumbers);
        // this.spreadInfo = this.oracleService.calculateSpreadInfo(this.pickedCards);

      });

  }

  selectCard(card, selectCard) {

    this.selectedCard = card;

    switch (selectCard) {
      case 'selectCardOne': {
        this.selectCardOne = true;
        this.selectCardTwo = false;
        this.selectCardThree = false;
        this.selectCardFour = false;
        this.selectCardFive = false;
        this.selectCardSix = false;
        this.selectCardSeven = false;
        this.selectCardEight = false;
        this.selectCardNine = false;
        this.selectCardTen = false;
        break;
      }
      case 'selectCardTwo': {
        this.selectCardOne = false;
        this.selectCardTwo = true;
        this.selectCardThree = false;
        this.selectCardFour = false;
        this.selectCardFive = false;
        this.selectCardSix = false;
        this.selectCardSeven = false;
        this.selectCardEight = false;
        this.selectCardNine = false;
        this.selectCardTen = false;
        break;
      }
      case 'selectCardThree': {
        this.selectCardOne = false;
        this.selectCardTwo = false;
        this.selectCardThree = true;
        this.selectCardFour = false;
        this.selectCardFive = false;
        this.selectCardSix = false;
        this.selectCardSeven = false;
        this.selectCardEight = false;
        this.selectCardNine = false;
        this.selectCardTen = false;
        break;
      }
      case 'selectCardFour': {
        this.selectCardOne = false;
        this.selectCardTwo = false;
        this.selectCardThree = false;
        this.selectCardFour = true;
        this.selectCardFive = false;
        this.selectCardSix = false;
        this.selectCardSeven = false;
        this.selectCardEight = false;
        this.selectCardNine = false;
        this.selectCardTen = false;
        break;
      }
      case 'selectCardFive': {
        this.selectCardOne = false;
        this.selectCardTwo = false;
        this.selectCardThree = false;
        this.selectCardFour = false;
        this.selectCardFive = true;
        this.selectCardSix = false;
        this.selectCardSeven = false;
        this.selectCardEight = false;
        this.selectCardNine = false;
        this.selectCardTen = false;
        break;
      }
      case 'selectCardSix': {
        this.selectCardOne = false;
        this.selectCardTwo = false;
        this.selectCardThree = false;
        this.selectCardFour = false;
        this.selectCardFive = false;
        this.selectCardSix = true;
        this.selectCardSeven = false;
        this.selectCardEight = false;
        this.selectCardNine = false;
        this.selectCardTen = false;
        break;
      }
      case 'selectCardSeven': {
        this.selectCardOne = false;
        this.selectCardTwo = false;
        this.selectCardThree = false;
        this.selectCardFour = false;
        this.selectCardFive = false;
        this.selectCardSix = false;
        this.selectCardSeven = true;
        this.selectCardEight = false;
        this.selectCardNine = false;
        this.selectCardTen = false;
        break;
      }
      case 'selectCardEight': {
        this.selectCardOne = false;
        this.selectCardTwo = false;
        this.selectCardThree = false;
        this.selectCardFour = false;
        this.selectCardFive = false;
        this.selectCardSix = false;
        this.selectCardSeven = false;
        this.selectCardEight = true;
        this.selectCardNine = false;
        this.selectCardTen = false;
        break;
      }
      case 'selectCardNine': {
        this.selectCardOne = false;
        this.selectCardTwo = false;
        this.selectCardThree = false;
        this.selectCardFour = false;
        this.selectCardFive = false;
        this.selectCardSix = false;
        this.selectCardSeven = false;
        this.selectCardEight = false;
        this.selectCardNine = true;
        this.selectCardTen = false;
        break;
      }
      case 'selectCardTen': {
        this.selectCardOne = false;
        this.selectCardTwo = false;
        this.selectCardThree = false;
        this.selectCardFour = false;
        this.selectCardFive = false;
        this.selectCardSix = false;
        this.selectCardSeven = false;
        this.selectCardEight = false;
        this.selectCardNine = false;
        this.selectCardTen = true;
        break;
      }
      default: {
        break;
      }
    }

    this.scrollToService.scrollTo(this.scrollToConfig);

  }

}
