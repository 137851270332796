import { Component, OnInit } from '@angular/core';
import { ExploreService } from '../explore.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-post-list',
  templateUrl: './post-list.component.html',
  styleUrls: ['./post-list.component.css']
})
export class PostListComponent implements OnInit {

  ideas: any;
  connectedIdeas: any;
  posts: any;

  constructor(
    public exploreService: ExploreService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.getIdeaAndConnections();
  }

  ngOnInit() {
  }

  getIdeaAndConnections() {

    this.route.params.subscribe(params => {

      // if we try to open page for new post and if idea is filtered
      // we have to set exploreService.showEditPost to true from here
      if (params['idea'] !== undefined && params['idea'] !== 'add') {

        // get connected ides
        this.exploreService.getIdeaAndConnections(params['idea'])
          .subscribe((data) => {
            this.ideas = data;
          });

        // get connections
        // this.exploreService.getConnectedIdeas(params['idea'])
        //   .subscribe((data) => {
        //     this.connectedIdeas = data;
        //   });

        this.exploreService.getPostsByIdeaId(params['idea'])
          .subscribe((data) => {
            this.posts = data;
          });

      } else if (params['idea'] !== 'add') {

        this.exploreService.selectedIdeas = [];
        this.exploreService.getIdeas()
          .subscribe((data) => {
            this.ideas = data;
          });

        this.getPosts();

      } else {
        this.exploreService.showEditPost = true;
      }

    });

  }

  getPosts() {

    this.exploreService.getPosts()
      .subscribe((data) => {
        this.posts = data;
      });

  }

  selectIdea(idea: any) {

    this.exploreService.selectedIdeas.push(idea);
    this.exploreService.selectedIdeasUrl();

  }

  removeIdea(id): void {

    const findIdea = this.ideas.find(function (item) {
      return item.id === id;
    });

    if (findIdea) {

      const removeIndex = this.ideas.map(function (item) { return item.id; }).indexOf(findIdea.id);

      if (removeIndex !== -1) {

        this.ideas.splice(removeIndex, 1);

      }

    }

  }

  onRemoveIdea(idea) {

    this.removeIdea(idea.id);
    this.exploreService.selectedIdeasUrl();

  }

  onClearIdeas() {

    this.router.navigate(['/explore/ideas']);

  }

}
