import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import * as mapboxgl from 'mapbox-gl';

@Injectable({
  providedIn: 'root'
})
export class MapService {

  map: any;
  popup: any;

  constructor() {
    this.popup = null;

  }

  buildMap() {

    mapboxgl.accessToken = environment.MAPBOX_API_KEY;
    this.map = new mapboxgl.Map({
      container: 'map', // container id
      // style: 'mapbox://styles/mapbox/streets-v11',
      style: 'mapbox://styles/ivantanasi/cju0duf2402p11fs4mze1l5wb',
      center: [0, 0], // starting position [lng, lat]
      zoom: 1.5, // starting zoom
      maxZoom: 3
    });

  }

  moveToSiberianTiger() {

    this.map.flyTo({
      center: [139.0410933, 48.0814846]
    });

    if (this.popup !== null) {
      this.popup.remove();
      this.popup = null;
    }

    if (this.popup === null) {

      this.popup = new mapboxgl.Popup({ closeOnClick: false })
        .setLngLat([139.0410933, 48.0814846])
        .setHTML(`<div style="width:200px;">
      <h4>Siberian tiger</h4>
      <img style="width:150px; height: auto;" src="https://upload.wikimedia.org/wikipedia/commons/b/b6/P.t.altaica_female.jpg">
      <p>The Siberian tiger is a tiger population in the Far East, particularly the Russian Far East and Northeast China.
      This population inhabits mainly the Sikhote Alin mountain region in southwest Primorye Province in the Russian Far East.</p>
      </div>`)
        .addTo(this.map);

    }

  }

  moveToEgyptianPyramids() {

    this.map.flyTo({
      center: [29.9792, 31.1342]
    });

    if (this.popup !== null) {
      this.popup.remove();
      this.popup = null;
    }

    if (this.popup === null) {

      this.popup = new mapboxgl.Popup({ closeOnClick: false })
        .setLngLat([29.9792, 31.1342])
        .setHTML(`<div style="width:200px;">
      <h4>Egyptian pyramids</h4>
      <img style="width:150px; height: auto;"
      src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/af/All_Gizah_Pyramids.jpg/1280px-All_Gizah_Pyramids.jpg">
      <p>The Egyptian pyramids are ancient pyramid-shaped masonry structures located in Egypt. As of November 2008, sources
      cite either 118 or 138 as the number of identified Egyptian pyramids. Most were built as tombs for the country's pharaohs
      and their consorts during the Old and Middle Kingdom periods.</p>
      </div>`)
        .addTo(this.map);

    }

  }

  moveToVincaCulture() {

    this.map.flyTo({
      center: [20.623056, 44.761944]
    });

    if (this.popup !== null) {
      this.popup.remove();
      this.popup = null;
    }

    if (this.popup === null) {

      this.popup = new mapboxgl.Popup({ closeOnClick: false })
        .setLngLat([20.623056, 44.761944])
        .setHTML(`<div style="width:200px;">
      <h4>Vinča culture</h4>
      <img style="height:150px; width: auto;"
      src="https://upload.wikimedia.org/wikipedia/commons/1/16/Vinca_clay_figure_02.jpg">
      <p>The Vinča culture, also known as Turdaș culture or Turdaș–Vinča culture, was a Neolithic archaeological culture in
      present-day Serbia and smaller parts of Bulgaria and Romania, dated to the period 5700–4500 BC or 5300–4700/4500 BC.</p>
      </div>`)
        .addTo(this.map);

    }

  }

}
