import { Component, OnInit } from '@angular/core';
import { OracleService } from '../../oracle.service';
import { Tarot } from '../../tarot';
import { SpreadInfo } from 'src/app/oracle/spread-info';

@Component({
  selector: 'app-star-spread',
  templateUrl: './star-spread.component.html',
  styleUrls: ['./star-spread.component.css']
})
export class StarSpreadComponent implements OnInit {

  pickedCards: Tarot[];
  randomNumbers: number[];
  spreadInfo: SpreadInfo;

  constructor(
    public oracleService: OracleService
  ) { }

  ngOnInit() {
  }

}
