import { Component, OnInit } from '@angular/core';
import { ProfileService } from 'src/app/profile/profile.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit {

  username = window.localStorage.getItem('username');
  insertUsername: string;


  constructor(
    private router: Router,
    private profileService: ProfileService
  ) {
    this.insertUsername = '';
  }

  ngOnInit() {
  }

  setUsername(event: any) {
    this.insertUsername = event.target.value;
  }

  deleteAccount() {

    this.profileService.deleteAccount(window.localStorage.getItem('user_id')).subscribe(
      response => {
        console.log('response' + response);
        this.router.navigate(['/wisdom/quotes']);
      },
      err => console.log(err)
    );

  }

}
