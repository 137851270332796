import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Svesnost } from './svesnost';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SvesnostService {

  API_URL = environment.API_URL;
  private httpOptions: any;

  constructor(private http: HttpClient) {

    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

  }

  generateSvesnostUnit(userId: string, unit: string) {

    const svesnost = new Svesnost();
    svesnost.user = userId;
    svesnost.unit = unit;

    this.http.patch<any>(`${this.API_URL}/generatesvesnostunit/` + userId + '/' + unit, this.httpOptions).subscribe(
      response => console.log(response),
      err => console.log(err)
    );

  }

}
