import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OracleService } from '../../oracle.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-delete-reading-dialog',
  templateUrl: './delete-reading-dialog.component.html',
  styleUrls: ['./delete-reading-dialog.component.css']
})
export class DeleteReadingDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DeleteReadingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private oracleService: OracleService,
    private router: Router,
  ) { }

  ngOnInit() {
  }

  deleteReading() {
    console.log(this.data);

    this.oracleService.deleteOracleReading(this.data.reading.id).subscribe(
      response => {
        console.log('response' + response);
        this.router.navigate(['/profile']);
      },
      err => console.log(err)
    );

    this.dialogRef.close();
  }

}
