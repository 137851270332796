import { Component, OnInit, OnDestroy } from '@angular/core';
import { OracleService } from '../../oracle.service';
import { AuthenticationService } from '../../../auth/auth.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-question-info',
  templateUrl: './question-info.component.html',
  styleUrls: ['./question-info.component.css']
})
export class QuestionInfoComponent implements OnInit, OnDestroy {

  questionInfoForm: FormGroup = new FormGroup({});

  oracleDecks: any;
  deckSpreads: any;
  questionKeywords: any;

  selectedDeck: any;
  selectedSpread: any;
  selectedQuestionKeywords: any;

  showOracle: boolean;
  startOpening: boolean;
  readingForGuest: boolean;

  constructor(
    private oracleService: OracleService,
    private authService: AuthenticationService,
    private router: Router
  ) { }

  ngOnInit() {

    this.showOracle = true;
    this.startOpening = false;
    this.readingForGuest = false;
    this.initForm();
    this.getOracleDecks();
    this.getDeckSpreads();
    // if (this.authService.isLoggedIn()) {
    //   this.getQuestionKeywords();
    // }

  }

  ngOnDestroy() {
    // don't save reading if user doesn't log in right after opening
    localStorage.removeItem('save_new_reading');
  }

  initForm() {

    this.questionInfoForm = new FormGroup({

      'selectedDeck': new FormControl('', Validators.required),

      'selectedSpread': new FormControl('', Validators.required),

      'selectedQuestionKeywords': new FormControl(''),

      'questionText': new FormControl('', Validators.required),

    });

  }

  getOracleDecks() {

    this.oracleService.getOracleDecks()
      .subscribe(oracleDecks => {

        this.oracleDecks = oracleDecks;
        // this.selectedDeck = oracleDecks[0];
        const deckCode = this.oracleService.selectedDeckCode;
        this.selectedDeck = this.oracleDecks.find(function (deck) {
          return deck.code === deckCode;
        });

        this.changeCardImage(this.selectedDeck.code);

      });

  }

  setOracleDeck() {
    this.selectedDeck = this.oracleService.selectedDeckCode;
    this.changeCardImage(this.selectedDeck.code);
  }

  getDeckSpreads() {

    this.oracleService.getDeckSpreads()
      .subscribe(deckSpreads => {

        this.deckSpreads = deckSpreads;
        this.selectedSpread = deckSpreads[0];

      });

  }

  getQuestionKeywords() {

    this.oracleService.getQuestionKeywords()
      .subscribe(questionKeywords => {

        this.questionKeywords = questionKeywords;

      });

  }

  onChangeDeck(event) {

    console.log(event);
    this.changeCardImage(this.selectedDeck.code);

  }

  onAddKeyword(event) {

    // console.log(event);
    if (event.id == null) {

      console.log('create new keyword ' + event.name);
      this.oracleService.createQuestionKeyword(event.name);


    }
    console.log(this.selectedQuestionKeywords);

  }

  changeCardImage(oracleCode: string) {

    switch (oracleCode) {
      case 'RIDER_WAITE':
        this.oracleService.spreadCardImage = require('../../../../assets/img/tarot/universal-waite-background.jpg');
        break;
      case 'ELDER_FUTHARK':
        this.oracleService.spreadCardImage = require('../../../../assets/img/runes/runestone.jpg');
        break;
      case 'NORSE_DEITIES':
        this.oracleService.spreadCardImage = require('../../../../assets/img/deities/norse-back.jpg');
        break;
      case 'GREEK_DEITIES':
        this.oracleService.spreadCardImage = require('../../../../assets/img/deities/greek-back.jpg');
        break;
      case 'ANCIENT_EGYPTIAN_DEITIES':
        this.oracleService.spreadCardImage = require('../../../../assets/img/deities/egypt-back.jpg');
        break;
      case 'SLAVIC_DEITIES':
        this.oracleService.spreadCardImage = require('../../../../assets/img/deities/slavic-back.jpg');
        break;
      case 'SPIRIT_ANIMALS':
        this.oracleService.spreadCardImage = require('../../../../assets/img/animals/animals-back.jpg');
        break;
      default:
        break;
    }

  }

  onSubmit() {

    // generate and save reading
    // generate random numbers for oracle cards
    let numberOfCards = null;
    switch (this.selectedSpread.code) {
      case 'ONE_CARD':
        numberOfCards = 1;
        break;
      case 'THREE_CARD':
        numberOfCards = 3;
        break;
      case 'THE_STAR':
        numberOfCards = 6;
        break;
      case 'CELTIC_CROSS':
        numberOfCards = 10;
        break;
      default:
        break;
    }

    const oracle = this.questionInfoForm.controls['selectedDeck'].value;
    const spread = this.questionInfoForm.controls['selectedSpread'].value;
    const question = this.questionInfoForm.controls['questionText'].value;
    const keywords = this.questionInfoForm.controls['selectedQuestionKeywords'].value;

    let randomNumbers = [];
    randomNumbers = this.oracleService.getRandomNumbers(oracle.code, numberOfCards);

    if (this.authService.isLoggedIn()) {

      this.oracleService.saveOracleReading(randomNumbers, oracle.id, spread.id, question, keywords);

      // effect for new oracle reading
      this.showOracle = false;
      this.startOpening = true;
      this.delay(1500).then(any => {
        this.startOpening = false;
        this.router.navigate(['/profile']);
      });

    } else {
      // Show opening for guest and option to sigin or signup
      let pickedCards = '';
      randomNumbers.forEach(function (number) {
        if (number !== undefined) {
          pickedCards += number + ',';
        }
      });
      pickedCards = pickedCards.substring(0, pickedCards.length - 1);

      const newReading = {
        oracle: oracle,
        spread: spread,
        question: question,
        reading: pickedCards,
        randomNumbers: randomNumbers // we need randomNumbers in number format for saveOracleReading
      };
      this.oracleService.reading = newReading;
      localStorage.setItem('save_new_reading', JSON.stringify(this.oracleService.reading));

      this.showOracle = false;
      this.startOpening = true;
      this.delay(1500).then(any => {
        this.showOracle = true;
        this.startOpening = false;
        this.readingForGuest = true;
      });
    }


  }

  async delay(ms: number) {
    await new Promise(resolve => setTimeout(() => resolve(), ms));
  }

}
