import { Component, OnInit } from '@angular/core';
import { QuoteService } from '../quote.service';

@Component({
  selector: 'app-quotes-actions',
  templateUrl: './quotes-actions.component.html',
  styleUrls: ['./quotes-actions.component.css']
})
export class QuotesActionsComponent implements OnInit {

  constructor(
    private quoteService: QuoteService
  ) {
    // this.iconCss = 'far fa-plus-square';
  }

  ngOnInit() {
  }

}
