import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-emoji',
  templateUrl: './emoji.component.html',
  styleUrls: ['./emoji.component.css']
})
export class EmojiComponent implements OnInit {

  emojiList: string[];
  emojiHtml: string;

  constructor() {
    this.emojiList = ['🌌', '🐾', '🏞️', '🦊', '🐻', '🍄', '👽', '🛸', '💫', '🐯', '🦁', '🌲', '🦉', '🎃', '🍂', '🐺', '🐍', '🕊️', '🍀',
      '🌹', '🌟', '☄️', '🦋', '🐬', '🏝️', '🌊', '🐳', '🗿', '🌋', '🗻', '🌙', '🏜️', '🌅', '🌈', '💎', '🔮', '📚', '📜', '🧙', '🌍'];
    this.emojiHtml = '';
  }

  ngOnInit() {
    this.randomEmoji();
  }

  randomEmoji() {

    const randomNumbers: number[] = [];
    while (randomNumbers.length < 7) {
      const randomnumber = Math.floor(Math.random() * 40) + 0;
      if (randomNumbers.indexOf(randomnumber) > -1) {
        continue;
      }
      randomNumbers[randomNumbers.length] = randomnumber;
      this.emojiHtml += this.emojiList[randomnumber];
    }
  }

}
