import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../sidenav/sidenav/navigation.service';
import { AuthenticationService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-page-toolbar',
  templateUrl: './page-toolbar.component.html',
  styleUrls: ['./page-toolbar.component.css']
})
export class PageToolbarComponent implements OnInit {

  constructor(
    private navigationService: NavigationService,
    public authService: AuthenticationService
  ) { }

  ngOnInit() {
  }

}
