import { Component, OnInit } from '@angular/core';
import { MapService } from '../map.service';

@Component({
  selector: 'app-world',
  templateUrl: './world.component.html',
  styleUrls: ['./world.component.css']
})
export class WorldComponent implements OnInit {


  constructor(
    private mapService: MapService
  ) { }

  ngOnInit() {

    console.log('buildMap');
    this.mapService.buildMap();

  }

}
