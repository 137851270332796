import { Component, OnInit } from '@angular/core';
import { OracleService } from '../oracle.service';

@Component({
  selector: 'app-spreads',
  templateUrl: './spreads.component.html',
  styleUrls: ['./spreads.component.css']
})
export class SpreadsComponent implements OnInit {

  constructor(
    public oracleService: OracleService
  ) {
  }

  ngOnInit() {
    // this.threeCardSpread();
  }

  // showSpreads(): boolean {
  //   return this.oracleService.showTarot && (this.oracleService.tarotSpread.oneCard || this.oracleService.tarotSpread.threeCard ||
  //     this.oracleService.tarotSpread.star || this.oracleService.tarotSpread.celticCross);
  // }

}
