import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/profile/user.service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ProfileService } from 'src/app/profile/profile.service';
import { AuthenticationService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-profile-settings',
  templateUrl: './profile-settings.component.html',
  styleUrls: ['./profile-settings.component.css']
})
export class ProfileSettingsComponent implements OnInit {

  constructor(
    public userService: UserService,
    private _snackBar: MatSnackBar,
    private profileService: ProfileService,
    private authService: AuthenticationService
  ) { }

  ngOnInit() {
    if (this.userService.user == null) {
      this.getUserInfo();
    }
  }

  getUserInfo() {

    const userId = window.localStorage.getItem('user_id');
    this.userService.getUserById(userId).subscribe(
      user => {
        this.userService.user = user;
        if (this.userService.user.profile_image == null) {
          window.localStorage.setItem('profile_image', '../../../../assets/img/wolf-spirit.png.jpg');
        } else {
          window.localStorage.setItem('profile_image', this.userService.user.profile_image);
        }
        window.localStorage.setItem('username', this.userService.user.username);
      },
      err => {
        this.authService.logout();
      }
    );
  }

  openSnackBar(message: string, action: string) {
    const config = new MatSnackBarConfig();
    config.horizontalPosition = 'center';
    config.panelClass = ['custom-mat-snack-bar'];
    config.duration = 6000;
    this._snackBar.open(message, action, config);
  }

  onProfileImageChanged(event) {

    const file = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
    const pattern = /image-*/;
    const reader = new FileReader();
    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);

  }

  _handleReaderLoaded(e) {
    const reader = e.target;
    this.profileService.updateProfileImage(reader.result).subscribe(
      (response: any) => {
        console.log(response);
        this.userService.user.profile_image = response.profile_image;
        this.openSnackBar('Profile image changed', 'OK');
      },
      err => {
        console.log(err);
        this.openSnackBar('Try with another picture', 'OK');
      }
    );
  }

  updateDisplayName(event: any) {
    const displayName = event.target.value;
    if (displayName.length > 0 && displayName !== this.userService.user.display_name) {
      const userId = window.localStorage.getItem('user_id');
      this.userService.updateDisplayName(userId, displayName).subscribe(
        response => {
          this.userService.user.display_name = response.display_name;
          this.openSnackBar('Display name changed', 'OK');
        },
        err => console.log(err)
      );
    }
  }

}
