import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { QuoteService } from 'src/app/quotes/quote.service';
import { Book } from 'src/app/quotes/book/book';

@Component({
  selector: 'app-book-edit',
  templateUrl: './book-edit.component.html',
  styleUrls: ['./book-edit.component.css']
})
export class BookEditComponent implements OnInit {

  bookEditForm: FormGroup = new FormGroup({});
  book: Book;

  customStyle = {
    selectButton: {
      'background-color': '#a2da8d',
      // 'color': '#9b9b9b',
      'border-radius': '.25rem',
      'font-size': '1rem',
      'text-transform': 'none'
    },
    clearButton: {
      'background-color': '#e0b1b5',
      // 'color': '#9b9b9b',
      'border-radius': '.25rem',
      'font-size': '1rem',
      'margin-left': '10px',
      'text-transform': 'none'
    },
    layout: {
      'border-radius': '.25rem',
      'padding-top': '5px',
      'border': '#ced4da solid 1px'
    },
    previewPanel: {
      'background-color': '#f8f8f8',
      'border-radius': '.25rem',
    }
  };

  constructor(
    private quoteService: QuoteService
  ) {

    this.book = new Book();

  }

  ngOnInit() {
    this.initForm();
  }

  initForm() {

    this.bookEditForm = new FormGroup({

      'title': new FormControl(this.quoteService.newBookTitle, [Validators.required]),
      'summary': new FormControl(''),
      'image': new FormControl('')

    });

  }

  bookImageSelected(event) {

    const file = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
    const pattern = /image-*/;
    const reader = new FileReader();
    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);

  }

  _handleReaderLoaded(e) {
    const reader = e.target;
    this.book.image = reader.result;
  }

  onSubmit() {

    console.log(this.quoteService.authorId);

    // Fill book with data

    this.book.title = this.bookEditForm.controls['title'].value;
    this.book.summary = this.bookEditForm.controls['summary'].value;
    this.book.author = this.quoteService.authorId;
    console.log(this.book);

    this.quoteService.saveBook(this.book);

    this.quoteService.newBook = false;

  }

  cancel() {

    this.quoteService.newBook = false;

  }



}
