import { Component, OnInit, OnDestroy } from '@angular/core';
import { OracleService } from '../oracle.service';
import { WisdomService } from '../wisdom.service';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-spirit-animal',
  templateUrl: './spirit-animal.component.html',
  styleUrls: ['./spirit-animal.component.css']
})
export class SpiritAnimalComponent implements OnInit, OnDestroy {

  constructor(
    private router: Router,
    public oracleService: OracleService,
    public wisdomService: WisdomService,
    private ngxService: NgxUiLoaderService
  ) {
    this.wisdomService.numOfImagesLoaded = 0;
    this.ngxService.startBackground('do-background-things');
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.ngxService.stopBackground('do-background-things');
  }

  selectSpiritAnimal(spiritAnimal: any) {
    this.wisdomService.selectedOracleCard = spiritAnimal;
  }

}
