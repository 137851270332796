import { Component, OnInit } from '@angular/core';
import { OracleService } from '../oracle.service';
import { AuthenticationService } from '../../auth/auth.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DeleteReadingDialogComponent } from './delete-reading-dialog/delete-reading-dialog.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-reading',
  templateUrl: './reading.component.html',
  styleUrls: ['./reading.component.css']
})
export class ReadingComponent implements OnInit {

  constructor(
    public oracleService: OracleService,
    public authService: AuthenticationService,
    public dialog: MatDialog,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {

    if (this.oracleService.reading == null) {
      this.route.params.subscribe(params => {
        const id = params['id'];
        this.oracleService.getOracleReadingById(id)
          .subscribe((data) => {
            this.oracleService.reading = data;
          });
      });
    }

  }

  deleteReading(reading: any) {

    const dialogRef = this.dialog.open(DeleteReadingDialogComponent, {
      width: '250px',
      data: { reading }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });

  }

}
