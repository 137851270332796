import { Component, OnInit } from '@angular/core';
import { QuoteService } from '../quote.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Author } from 'src/app/quotes/author/author';

@Component({
  selector: 'app-author-edit',
  templateUrl: './author-edit.component.html',
  styleUrls: ['./author-edit.component.css']
})
export class AuthorEditComponent implements OnInit {

  authorEditForm: FormGroup = new FormGroup({});
  author: Author;

  customStyle = {
    selectButton: {
      'background-color': '#a2da8d',
      // 'color': '#9b9b9b',
      'border-radius': '.25rem',
      'font-size': '1rem',
      'text-transform': 'none'
    },
    clearButton: {
      'background-color': '#e0b1b5',
      // 'color': '#9b9b9b',
      'border-radius': '.25rem',
      'font-size': '1rem',
      'margin-left': '10px',
      'text-transform': 'none'
    },
    layout: {
      'border-radius': '.25rem',
      'padding-top': '5px',
      'border': '#ced4da solid 1px'
    },
    previewPanel: {
      'background-color': '#f8f8f8',
      'border-radius': '.25rem',
    }
  };

  constructor(
    private quoteService: QuoteService
  ) {

    this.author = new Author();

  }

  ngOnInit() {

    this.initForm();

  }

  initForm() {

    const name: string[] = this.quoteService.newAuthorName.split(' ');

    const firstName: string = name[0];
    const lastName: string = name[1];

    this.authorEditForm = new FormGroup({

      // 'firstName': new FormControl(firstName, [Validators.required]),

      // 'lastName': new FormControl(lastName, [Validators.required]),

      'fullName': new FormControl(this.quoteService.newAuthorName, [Validators.required]),

      'image': new FormControl('')

    });

  }

  authorImageSelected(event) {

    const file = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
    const pattern = /image-*/;
    const reader = new FileReader();
    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);

  }

  _handleReaderLoaded(e) {
    const reader = e.target;
    this.author.image = reader.result;
  }

  onSubmit() {

    // Fill author with data
    // this.author.first_name = this.authorEditForm.controls['firstName'].value;
    // this.author.last_name = this.authorEditForm.controls['lastName'].value;
    this.author.full_name = this.authorEditForm.controls['fullName'].value;

    this.quoteService.saveAuthor(this.author);

    this.quoteService.newAuthor = false;

  }

  cancel() {

    this.quoteService.newAuthor = false;

  }

}
