import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { QuoteService } from '../quote.service';

@Component({
  selector: 'app-book',
  templateUrl: './book.component.html',
  styleUrls: ['./book.component.css']
})
export class BookComponent implements OnInit, OnDestroy {

  private sub: any;
  book: any;
  quotes: any;

  constructor(
    private route: ActivatedRoute,
    private quoteService: QuoteService
  ) { }

  ngOnInit() {

    this.sub = this.route.params.subscribe(params => {
      const id = +params['id']; // (+) converts string 'id' to a number
      this.getBookInfo(id);
      this.getBookQuotes(id);
    });
  }

  getBookInfo(id: number) {
    this.quoteService.getBookInfo(id)
      .subscribe((data) => {
        this.book = data;
      });
  }

  getBookQuotes(id: number) {
    this.quoteService.getBookQuotes(id)
      .subscribe((data) => {
        this.quotes = data;
      });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

}
