import { Component, OnInit, Input } from '@angular/core';
import { OracleService } from '../../oracle.service';
import { Tarot } from '../../tarot';
import { SpreadInfo } from 'src/app/oracle/spread-info';

@Component({
  selector: 'app-celtic-cross',
  templateUrl: './celtic-cross.component.html',
  styleUrls: ['./celtic-cross.component.css']
})
export class CelticCrossComponent implements OnInit {

  pickedCards: Tarot[];
  randomNumbers: number[];
  spreadInfo: SpreadInfo;

  constructor(
    public oracleService: OracleService
  ) { }

  ngOnInit() {
  }

}
