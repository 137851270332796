import { Component, OnInit } from '@angular/core';
import { QuoteService } from '../quote.service';
import { TagService } from '../tag.service';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../auth/auth.service';

@Component({
  selector: 'app-quotes-list',
  templateUrl: './quotes-list.component.html',
  styleUrls: ['./quotes-list.component.css']
})
export class QuotesListComponent implements OnInit {

  quotes: any;
  private tags: any;
  searchedTags: any;

  constructor(
    private quoteService: QuoteService,
    public tagService: TagService,
    private route: ActivatedRoute,
    public authService: AuthenticationService,
  ) { }

  ngOnInit() {

    this.route.params.subscribe(params => {

      // if there is no params get random quotes
      this.tags = params['tag'];
      this.getQuotes(this.tags);

    });

  }

  getQuotes(tags) {

    // api
    this.quoteService.getQuotes(tags)
      .subscribe((data) => {
        this.quotes = data;
      });

  }

  onChangeTags(event) {

    this.tagService.selectedTagsUrl();
    this.searchedTags = null;

  }

  onSearchTags(event) {

    if (event.length >= 3) {

      this.quoteService.getTags(event)
        .subscribe((data) => {
          this.searchedTags = data;
        });
    }
  }

  loadMoreQuotes(url: string) {

    this.quoteService.loadMoreQuotes(url)
      .subscribe((data) => {
        this.quotes.next = data['next'];
        data['results'].forEach(quote => {
          this.quotes.results.push(quote);
        });
      });
  }

}
