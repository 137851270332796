import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ProfileService {

    API_URL = environment.API_URL;
    private httpOptions: any;

    currentSteps: any;
    futureSteps: any;
    completedSteps: any;

    currentStep: any;

    constructor(
        private http: HttpClient
    ) {

        this.httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' })
        };

    }

    getUserGoal() {

        return this.http.get(`${this.API_URL}/usergoal/1`);

    }

    getGoalSteps() {

        return this.http.get(`${this.API_URL}/goalsteps/1`);

    }

    getCurrentGoalStep() {

        this.http.get(`${this.API_URL}/currentgoalstep/1/0`).
            subscribe((data) => {

                this.currentStep = data;

            });

    }

    createNewStepTowardGoal(goalStepDescription: string) {

        const newStep = {
            goal: '1',
            description: '',
            position: '0',
            stage: '1'
        };

        newStep.description = goalStepDescription;

        return this.http.post<any>(`${this.API_URL}/createnewsteptowardgoal/`, newStep, this.httpOptions);

    }

    updateGoalStepPosition(goalStepId: string, newPosition: string, stepStageCodeId: string) {

        return this.http.patch<any>(`${this.API_URL}/updategoalstepposition/` + goalStepId + '/' + newPosition +
            '/' + stepStageCodeId, this.httpOptions);

    }

    updateGoalStepDescription(goalStepId: string, goalStepDescription: string) {

        return this.http.patch<any>(`${this.API_URL}/updategoalstepdescription/` + goalStepId + '/' +
            goalStepDescription, this.httpOptions);

    }

    updateProfileImage(profileImage: any) {

        const user = {
            id: window.localStorage.getItem('user_id'),
            profile_image: profileImage
        };

        // return this.http.patch<any>(`${this.API_URL}/update-users-profile-image/` + user.id, user, this.httpOptions);
        return this.http.put<any>(`${this.API_URL}/update-users-profile-image/` + user.id, user, this.httpOptions);
    }

    deleteAccount(id: string) {
        return this.http.delete<any>(`${this.API_URL}/delete-account/` + id, this.httpOptions);
    }

}
