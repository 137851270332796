import { Component, OnInit, OnDestroy } from '@angular/core';
import { OracleService } from '../oracle.service';
import { WisdomService } from '../wisdom.service';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-runes',
  templateUrl: './runes.component.html',
  styleUrls: ['./runes.component.css']
})
export class RunesComponent implements OnInit, OnDestroy {

  constructor(
    private router: Router,
    public oracleService: OracleService,
    private wisdomService: WisdomService,
    private ngxService: NgxUiLoaderService
  ) {
    this.wisdomService.numOfImagesLoaded = 0;
    this.ngxService.startBackground('do-background-things');
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.ngxService.stopBackground('do-background-things');
  }

  selectRune(rune: any) {
    this.wisdomService.selectedOracleCard = rune;
  }

}
