import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class NavigationService {

  navigationPages: { [key: string]: boolean } = {};

  constructor(
    private router: Router
  ) {

    this.navigationPages['profile_is_active'] = false;

    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        // console.log(val.url);
        this.navigationLogic(val.url);
      }
    });

  }

  navigationLogic(url: string) {

    this.navigationPages['profile_is_active'] = false;
    this.navigationPages['profile_spirit_is_active'] = false;
    this.navigationPages['profile_oracle_is_active'] = false;
    this.navigationPages['profile_library_is_active'] = false;
    this.navigationPages['spirit_animals_is_active'] = false;
    this.navigationPages['tarot_is_active'] = false;
    this.navigationPages['runes_is_active'] = false;
    this.navigationPages['quotes_is_active'] = false;
    this.navigationPages['world_map_is_active'] = false;

    switch (url) {
      case '/profile':
      case '/profile/spirit':
      case '/profile/image':
        this.navigationPages['profile_is_active'] = true;
        this.navigationPages['profile_spirit_is_active'] = true;
        break;
      case '/profile/oracle':
        this.navigationPages['profile_is_active'] = true;
        this.navigationPages['profile_oracle_is_active'] = true;
        break;
      case '/profile/library':
        this.navigationPages['profile_is_active'] = true;
        this.navigationPages['profile_library_is_active'] = true;
        break;
      // case '/profile/goals':
      case '/wisdom/spiritanimals':
        this.navigationPages['spirit_animals_is_active'] = true;
        break;
      case '/wisdom/tarot':
        this.navigationPages['tarot_is_active'] = true;
        break;
      case '/wisdom/runes':
        this.navigationPages['runes_is_active'] = true;
        break;
      case '/wisdom/quotes':
        this.navigationPages['quotes_is_active'] = true;
        break;
      case '/explore/world':
        this.navigationPages['world_map_is_active'] = true;
        break;
      default:
        break;
    }

  }

}
