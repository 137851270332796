import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Post } from 'src/app/explore/post/post';
import { ExploreService } from '../explore.service';

@Component({
  selector: 'app-post-edit',
  templateUrl: './post-edit.component.html',
  styleUrls: ['./post-edit.component.css']
})
export class PostEditComponent implements OnInit {

  postEditForm: FormGroup = new FormGroup({});
  post: Post;
  ideas: any;
  postIdeas: any;

  constructor(
    private router: Router,
    private exploreService: ExploreService
  ) {
    this.post = new Post();
  }

  ngOnInit() {

    this.initForm();
    this.getIdeas();

  }

  initForm() {

    this.postEditForm = new FormGroup({

      'postIdea': new FormControl('', Validators.required),

      'content': new FormControl(`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
          ut labore et dolore magna aliqua.`, Validators.required),

      'contentLink': new FormControl(''),

      'image': new FormControl('')


    });

  }

  getIdeas() {

    this.exploreService.getIdeas()
      .subscribe((data) => {
        this.ideas = data;
      });

  }

  selectIdea(idea: any) {



  }

  postImageSelected(event) {

    const file = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
    const pattern = /image-*/;
    const reader = new FileReader();
    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);

  }

  _handleReaderLoaded(e) {
    const reader = e.target;
    this.post.image = reader.result;
  }

  onSubmit() {

    // Fill author with data
    this.post.user = window.localStorage.getItem('user_id');
    this.post.idea = this.postEditForm.controls['postIdea'].value.id.toString();
    this.post.content = this.postEditForm.controls['content'].value;
    this.post.content_link = this.postEditForm.controls['contentLink'].value;

    this.exploreService.savePost(this.post);

  }

  postEditClose() {

    this.router.navigate(['/explore/ideas']);

  }

}
