import { Component, OnInit } from '@angular/core';
import { TagService } from '../tag.service';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.css']
})
export class TagsComponent implements OnInit {

  constructor(
    public tagService: TagService
  ) { }

  ngOnInit() {
  }

  removeTag(id: string): void {

    this.tagService.removeTag(id);

  }
}
