import { Component, OnInit } from '@angular/core';
import { ExploreService } from '../explore.service';

@Component({
  selector: 'app-mind',
  templateUrl: './mind.component.html',
  styleUrls: ['./mind.component.css']
})
export class MindComponent implements OnInit {

  ideas: any;
  connectedIdeas: any;
  posts: any;

  constructor(
    public exploreService: ExploreService
  ) {

    // this.getIdeaAndConnections();

  }

  ngOnInit() { }

}
