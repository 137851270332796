import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Quote } from './quote';
import { Author } from './author/author';
import { Book } from './book/book';
import { TagService } from './tag.service';

@Injectable({
  providedIn: 'root'
})
export class QuoteService {

  API_URL = environment.API_URL;

  private httpOptions: any;

  newAuthor: boolean;
  newAuthorName: string;
  newCreatedAuthor: any;
  authorId: string;

  newBook: boolean;
  newBookTitle: string;
  newCreatedBook: any;

  constructor(
    private http: HttpClient,
    public tagService: TagService
  ) {

    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    this.newAuthor = false;
    this.newAuthorName = '';
    this.newBook = false;
  }

  /** GET quotes from the server */
  getQuotes(tags) {

    if (tags != null) {
      return this.http.get(`${this.API_URL}/quotesbytags/` + tags);
    } else {

      // all quotes
      this.tagService.selectedTags = [];
      return this.http.get(`${this.API_URL}/quotes/`);

      // TODO: random quotes
      // once in 24h get all quotes id's
      // get 6 or 9 random id's and get those quotes from db

    }
  }

  loadMoreQuotes(url: string) {
    return this.http.get(url);
  }

  saveQuote(quote: Quote) {

    this.http.post<Quote>(`${this.API_URL}/savequote/`, quote, this.httpOptions).subscribe(
      response => console.log(response),
      err => console.log(err)
    );

  }

  saveAuthor(author: Author) {

    this.http.post<Author>(`${this.API_URL}/saveauthor/`, author, this.httpOptions).subscribe(
      response => {

        this.newCreatedAuthor = response;

        console.log(response);
      },
      err => console.log(err)
    );

  }

  getAuthorInfo(id: number) {
    return this.http.get(`${this.API_URL}/author/` + id);
  }

  getAuthorQuotes(id: number) {
    return this.http.get(`${this.API_URL}/authorquotes/` + id);
  }

  getAuthorsByFirstAndLastName(firstName: string, lastName: string) {
    return this.http.get(`${this.API_URL}/authors/` + firstName + '/' + lastName);
  }


  getAuthorsByFullName(fullName: string) {
    return this.http.get(`${this.API_URL}/authorsbyfullname/` + fullName);
  }

  getAuthorBooks(authorId: string) {
    return this.http.get(`${this.API_URL}/booksbyauthor/` + authorId);
  }

  authorBooksByTitle(authorId: string, bookTitle: string) {
    return this.http.get(`${this.API_URL}/authorbooksbytitle/` + authorId + '/' + bookTitle);
  }

  getBookInfo(id: number) {
    return this.http.get(`${this.API_URL}/book/` + id);
  }

  getBookQuotes(id: number) {
    return this.http.get(`${this.API_URL}/bookquotes/` + id);
  }

  saveBook(book: Book) {

    this.http.post<Book>(`${this.API_URL}/savebook/`, book, this.httpOptions).subscribe(
      response => {
        this.newCreatedBook = response;
        console.log(response);
      },
      err => console.log(err)
    );

  }

  getTags(tagName: string) {
    return this.http.get(`${this.API_URL}/tags/` + tagName);
  }

  getTagByName(name: string) {
    return this.http.get(`${this.API_URL}/tag/` + name);
  }

  createTag(name: string) {

    const tag = {
      name: ''
    };
    tag.name = name;

    this.http.post<any>(`${this.API_URL}/createtag/`, tag, this.httpOptions).subscribe(
      // response => console.log(response),
      // err => console.log(err)
    );

  }

}
